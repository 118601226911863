<template>
  <div>
    <OrderForm v-if="orderInView" :order="orderInView"></OrderForm>
    <div>
      <OrderDriverSearch v-if="orderInView && orderInView.id" :order="orderInView"></OrderDriverSearch>
    </div>
    <div v-if="orderInView && orderInView.id"  class="pl-3">
      <OrderTimeline v-if="orderInView && orderInView.id" :order-id="orderInView.id"></OrderTimeline>
    </div>
  </div>
</template>

<script>
import OrderForm from '@/components/dashboard/Operator/OrderForm'
import OrderDriverSearch from '@/components/dashboard/Operator/OrderDriverSearch'
import OrderTimeline from '@/components/common/OrderTimeline'

export default {
  name: 'OrderView',
  components: {
    OrderTimeline,
    OrderForm: OrderForm,
    OrderDriverSearch: OrderDriverSearch
  },
  mounted () {
    this.$store.dispatch('dispatcherDashboard/getServices')
    this.getOrderData()
  },
  computed: {
    orderInView () {
      return this.$store.getters['dispatcherDashboard/getOrderInView']
    }
  },
  methods: {
    getOrderData () {
      const orderId = parseInt(this.$route.params.id)
      if (this.$store.state.dispatcherDashboard.orderInView === null || this.$store.state.dispatcherDashboard.orderInView.id !== orderId) {
        if (orderId !== 0) {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.$route.params.id })
        } else {
          this.$store.commit('dispatcherDashboard/createNewOrder')
        }
      }
    }
  },
  watch: {
    '$route.params.id' () {
      this.getOrderData()
    }
  }
}
</script>

<style scoped>

</style>
