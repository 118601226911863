<template>
  <div>
    <b-row v-if="includeDriverPayments" class="mb-3">
      <b-col md="3">
        <label>Payment type</label>
        <b-select v-model="driverPaymentMethod" size="sm">
          <b-select-option :value="null">All</b-select-option>
          <b-select-option value="cash">Cash</b-select-option>
          <b-select-option value="card">Card</b-select-option>
          <b-select-option value="protocol">Protocol</b-select-option>
          <b-select-option value="discount">Discount</b-select-option>
        </b-select>
      </b-col>
      <b-col md="3">
        <label>Payment status</label>
        <b-select v-model="driverPayment" size="sm">
          <b-select-option :value="null">-</b-select-option>
          <b-select-option value="paid">Paid</b-select-option>
          <b-select-option value="unpaid">Unpaid</b-select-option>
        </b-select>
      </b-col>
    </b-row>
    <b-list-group v-if="Array.isArray(driversLocations)" :style="{height: height + 'px', overflowY: 'scroll'}">
      <b-list-group-item button v-for="order in driversLocations" @click="onRowClicked(order.id)" :key="order.id">
        <b-row>
          <b-col :md="includeDriverPayments ? 3 : 4">#{{ order.id }} - {{ order.created_at }}</b-col>
          <b-col :md="includeDriverPayments ? 3 : 4">

            <div v-if="order.places[0] && order.places[0].name" :title="order.places[0].name">
              <span v-if="order.places[0].name.length >= 30">
                {{ order.places[0].name.substring(0,30)+".." }}
              </span>
              <span v-else>{{ order.places[0].name }}</span>
            </div>
          </b-col>
          <b-col md="2">
            {{ order.client.name }}
          </b-col>
          <b-col v-if="includeDriverPayments" md="2">
            <template v-if="order.payment_type === 'card'">
              <div v-if="order.finance_info !== null">CARD {{ (order.finance_info.driver_payment - (order.discount_amount !== null ? order.discount_amount : 0)).toFixed(2) }} {{ order.finance_info.is_paid ? 'PAID' : 'UNPAID' }}</div>
              <div v-if="order.discount_condition_id !== null">
                DISC {{ order.discount_amount.toFixed(2) }} {{ order.discount_compensated ? 'PAID' : 'UNPAID' }}
              </div>
            </template>
            <template v-else-if="order.payment_type === 'protocol'">
              <div>PROTOCOL {{ (order.cost - (order.discount_amount !== null ? order.discount_amount : 0)).toFixed(2) }} UNPAID</div>
              <div v-if="order.discount_condition_id !== null">
                DISC {{ order.discount_amount.toFixed(2) }} {{ order.discount_compensated ? 'PAID' : 'UNPAID' }}
              </div>
            </template>
            <template v-else>
              CASH
              <div v-if="order.discount_condition_id !== null">
                DISC {{ order.discount_amount.toFixed(2) }} {{ order.discount_compensated ? 'PAID' : 'UNPAID' }}
              </div>
            </template>
          </b-col>
          <b-col v-else>
            <span v-if="order.state === 'finished'">F</span>
            <span v-if="order.state === 'canceled'">A</span>
            <span v-if="order.state === 'assigned'">I</span> -
            {{ order.cost ? order.cost : '0.00' }} RON
            ({{ order.payment_type }})
          </b-col>
          <b-col v-if="includeDriverPayments">
            <template v-if="typeof order.finance_info !== 'undefined' && order.finance_info !== null">
              <b-badge v-if="order.finance_info.is_paid" variant="success">PAID</b-badge>
              <b-badge v-else variant="danger">UNPAID</b-badge>
            </template>
            <template v-if="order.discount_condition_id !== null">
              <b-badge v-if="order.discount_compensated" variant="success">PAID</b-badge>
              <b-badge v-else variant="danger">UNPAID</b-badge>
            </template>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>

    <b-row v-if="Array.isArray(driversLocations)" align-content="center" class="mt-2">
      <b-col>
        <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
        >
        </b-pagination>
      </b-col>
      <b-col>
        <b-list-group horizontal>
          <b-list-group-item class="p-2" >Total comenzi: </b-list-group-item>
          <b-list-group-item class="p-2" >{{ meta.total }}</b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'GetOrders',
  props: {
    userId: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      default: 300
    },
    includeDriverPayments: {
      type: Boolean,
      default: false
    },
    dateStart: {
      type: String,
      default: null
    },
    dateEnd: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.getDriverOrders()
  },
  data () {
    return {
      driversLocations: null,
      meta: null,
      perPage: 30,
      currentPage: 1,
      driverPaymentMethod: null,
      driverPayment: null
    }
  },
  computed: {
    rows () {
      return this.meta ? this.meta.total : 0
    },
    params () {
      return {
        driver_id: this.$props.userId,
        page: this.currentPage,
        per_page: this.perPage,
        date_from: this.dateStart,
        date_to: this.dateEnd,
        include_payments: this.includeDriverPayments,
        driver_payment_method: this.driverPaymentMethod,
        driver_payment: this.driverPayment
      }
    }
  },
  methods: {
    onRowClicked (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    },
    getDriverOrders () {
      this.$axios.get(config.baseApiUrl + '/api/orders', {
        params: this.params
      })
        .then(response => {
          this.driversLocations = response.data.data
          this.meta = response.data.meta
        })
    }
  },
  watch: {
    currentPage: function () {
      this.getDriverOrders()
    },
    paymentMethod: function () {
      this.getDriverOrders()
    },
    driverPayment: function () {
      this.getDriverOrders()
    },
    driverPaymentMethod: function () {
      this.getDriverOrders()
    },
    userId: function () {
      this.$axios.get(config.baseApiUrl + '/api/orders', {
        params: this.params
      })
        .then(response => {
          this.driversLocations = response.data.data
          this.meta = response.data.meta
        })
    },
    props: function (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    }
  }
}
</script>

<style scoped>
</style>
