<template>
  <div class="mt-2">
    <template v-if="orders">
      <OrdersList :orders="orders" @order-click="viewOrder"></OrdersList>
    </template>
    <div v-else class="text-center">Loading...</div>
  </div>

</template>

<script>
import OrdersList from '@/components/dashboard/Operator/Orders/OrdersList'

export default {
  name: 'FinishedOrders',
  components: {
    OrdersList
  },
  mounted () {
    this.fetchOrders()
  },
  computed: {
    orders () {
      return this.$store.getters['dispatcherDashboard/getOrders']
    }
  },
  methods: {
    fetchOrders () {
      this.$store.dispatch('dispatcherDashboard/getOrders', { type: 'finished' })
    },
    viewOrder (order) {
      this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: order.id })
    }
  }
}
</script>

<style scoped>

</style>
