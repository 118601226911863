<template>
  <div>
    <div v-if="$store.state.isGoogleApiLoaded" class="position-relative">
      <div class="driverSearch">
        <p>Caută după indicativ: </p>
        <b-form-input autocomplete="off"  class="driverSearchIndicativ" placeholder="___" v-model="markerSearch"></b-form-input>
        <b-button class="ml-2" size="sm" variant="primary" @click="togglePoly">Afișează zonele</b-button>
      </div>
      <GmapMap
        v-if="driversLocations"
        ref="map"
        :center="this.$store.getters['auth/loggedUser'].id !== 1 ? mapCenter : { lat: 45.9442858, lng: 25.0094303 }"
        :zoom="this.$store.getters['auth/loggedUser'].id !== 1 ? 13 : 7"
        :options="MapStyle"
        style="width: 100%; height: calc(100vh - 209px); border-bottom: 1px solid #bdc1c6"
        :key="''"
      >
        <!-- <GmapCluster
          :gridSize="30"
          :zoomOnClick="true"
          :minimumClusterSize="2"

        > -->
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"
      >
        {{infoContent}}
      </gmap-info-window>
          <template v-if="showPoly === true">
            <gmap-polygon class="mapPoly" v-for="poly in polygonsGoogleList"
              v-bind:key="'polygon-' + poly.id"
              :paths="poly.paths"
              :editable="false"
              :options="{fillColor:  fillC, fillOpacity: 0.5,strokeOpacity: 1,strokeWeight: 1, strokeColor: '#fff'}"
              :draggable="false"
              @click="showPolygonDesc($event, poly.name)"
              ref="polygon"
            ></gmap-polygon>
          </template>
          <GmapMarker
            :key="index"
            v-for="(m, index) in driversLocationsFiltered"
            :position="{lat: m.lat, lng: m.long}"

            :clickable="true"
            :icon="markerIcon(m)"
            :draggable="false"
            :label="{color:'#fff', fontSize:'9px',fontWeight:'bold', text: m.indicativ}"
            @click="driverInfo(m.userId)"
          />

        <!-- </GmapCluster> -->
      </GmapMap>

      <div class="driverInfo" v-show="showDriverInfo" :class="{ showDriver: showDriverInfo }">
        <b-card>
          <b-card-body body-class="p-0">
            <b-card-title>{{ Sofer.identifier ? Sofer.identifier : '' }} <b-button variant="danger" size="sm" class="pull-right" @click="hideDriverInfo"><b-icon icon="x"></b-icon></b-button></b-card-title>
            <b-card-sub-title class="mb-2">{{ Sofer.name }}  </b-card-sub-title>
            <b-card-text>
              <table class="w-100">
                <tr>
                  <td>Balanța: </td>
                  <td>{{ Sofer.balance }} RON</td>
                </tr>
                <tr>
                  <td>Ocupat: </td>
                  <td v-if="Sofer.is_busy" class="text-danger">Da</td>
                  <td v-else class="text-success">Nu</td>
                </tr>
                <tr>
                  <td>Prioritate:</td>
                  <td v-if="Sofer.high_priority != 0" class="text-success">Da <b-icon icon="star-fill"></b-icon></td>
                  <td v-else class="text-success">Nu</td>
                </tr>
                <!-- <tr>
                  <td>Aplicație:</td>
                  <td>
                    <UserMeta :type="'simple'" :user-id="Sofer.id" />
                  </td>
                </tr> -->
              </table>
              <div class="mt-2" v-if="Sofer.is_busy">

                <strong>Comenzi active:</strong>
                <b-list-group>
                  <b-list-group-item  class="d-flex px-2 py-1" :to="{ name: 'OperatorOrderView', params: { id: order.id } }" v-for="order in driverHistory" :key="order.id">
                    <span class="mr-2"> #{{ order.id }}: </span>
                    <div v-if="order.places[0].name">
                      <span v-if="order.places[0].name.length >= 35">
                        {{ order.places[0].name.substring(0,35)+".." }}
                      </span>
                      <span v-else>{{ order.places[0].name }}</span>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-card-text>

          </b-card-body>

            <template #footer>
              <b-row>
                <b-button class="ml-3 mr-1" size="sm" variant="secondary" target="_blank" :href="'/user/' + Sofer.id + '/driver'"> Edit <b-icon icon="pencil-square"></b-icon></b-button>
                <UserBanControl v-if="Sofer" :user="Sofer"></UserBanControl>
                <b-button disabled v-b-modal.driverOrders :user-id="Sofer.id" class="mx-1" size="sm" variant="primary"> Istoric comenzi <b-icon icon="list"></b-icon></b-button>
                <b-button @click="openMessages" class="ml-1 mr-3" size="sm" variant="success"> Mesaj <b-icon icon="envelope"></b-icon></b-button>
              </b-row>

            </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import mapIcoFree from '@/assets/driverLocFree.svg'
import mapIcoBusy from '@/assets/driverLocBusy.svg'
import mapIcoPaused from '@/assets/driverLocBan.svg'
import config from '@/config'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'
import ClickOutside from 'vue-click-outside'
import UserBanControl from '@/components/users/UserBanControl'
// import GerOrders from '@/components/orders/GetOrders'
// import GmapCluster from 'vue2-best-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'DriversMap',
  components: { UserBanControl /*,  GmapCluster */ },
  data () {
    return {
      polygons: [],
      polygonsRawList: [],
      driversLocations: [],
      driversData: [],
      driverHistory: [],
      driverD: '',
      selected: null,
      optionsSaved: [
        { value: null, text: 'Barbu Lautaru 12' },
        { value: 'a', text: 'Aleea Florilor 7B' }
      ],
      MapStyle: {
        mapTypeControl: false,
        streetViewControl: false
      },
      showDriverInfo: false,
      showPoly: false,
      // InfoWindow
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoContent: '',
      infoWinOpen: false,
      fillC: [
        '#000',
        '#fff'
      ],
      selectedColor: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      Sofer: '',
      getDriverPositionsInterval: null,
      markerSearch: '',
      mapCenter: this.$store.getters['auth/companyLocation']
    }
  },
  mounted () {
    this.popupItem = this.$el
    this.getDriverPositions()
    this.getSavedPolygons()

    this.getDriverPositionsInterval = setInterval(this.getDriverPositions, 25000)
  },
  beforeDestroy () {
    clearInterval(this.getDriverPositionsInterval)
  },
  computed: {
    polygonsGoogleList () {
      return this.polygonsRawList.map((item) => {
        const itemNew = Object.assign({}, item)
        itemNew.paths = []
        item.paths.forEach((it) => {
          itemNew.paths.push(new window.google.maps.LatLng(it.lat, it.long))
        })
        return itemNew
      })
    },
    driversLocationsFiltered () {
      if (this.markerSearch) {
        return this.driversLocations.filter((item) => {
          return item.indicativ === this.markerSearch
        })
      }
      return this.driversLocations
    }
  },
  methods: {

    showPolygonDesc (event, name) {
      console.log(event)
      const contentString = name
      this.infoWindowPos = event.latLng
      this.infoContent = contentString
      this.infoWinOpen = true
    },
    togglePoly () {
      this.showPoly = !this.showPoly
    },
    getSavedPolygons () {
      return Vue.axios.get(config.baseApiUrl + '/api/polygons')
        .then((response) => {
          this.polygonsRawList = response.data.data.map((polygon) => {
            polygon.isVisible = true
            return polygon
          })
        })
    },
    getDriverOrders (driverId) {
      this.$axios.get(config.baseApiUrl + '/api/orders?driver_id=' + driverId + '&state=assigned')
        .then(response => {
          this.driverHistory = response.data.data
        })
    },
    toggleDriverInfo () {
      this.showDriverInfo = true
    },
    hideDriverInfo () {
      this.showDriverInfo = false
    },
    getDriverPositions () {
      this.$axios.get(config.baseApiUrl + '/api/drivers-positions')
        .then(response => {
          this.driversLocations = response.data
        })
    },
    getDriverData (driverId) {
      this.$axios.get(config.baseApiUrl + '/api/users/' + driverId)
        .then(response => {
          this.Sofer = response.data
        })
    },
    driverInfo (driverId) {
      this.showDriverInfo = true
      this.getDriverData(driverId)
      this.getDriverOrders(driverId)
    },
    markerIcon (data) {
      if (data.is_busy) {
        return {
          url: mapIcoBusy,
          strokeColor: '#ffffff',
          scaledSize: { width: 26, height: 26 },
          labelOrigin: { x: 13, y: 13 }
        }
      }
      if (data.is_paused) {
        return {
          url: mapIcoPaused,
          strokeColor: '#ffffff',
          scaledSize: { width: 26, height: 26 },
          labelOrigin: { x: 13, y: 13 }
        }
      }
      return {
        url: mapIcoFree,
        strokeColor: '#ffffff',
        scaledSize: { width: 26, height: 26 },
        labelOrigin: { x: 13, y: 13 }
      }
    },
    openMessages () {
      this.$store.state.messages.isOpen = true
      this.$store.dispatch('messages/fetchConversations').then(() => {
        setTimeout(() => {
          this.$store.commit('messages/addNewConversation', {
            conversation: {
              last_message_at: null,
              interlocutor_id: this.Sofer.id,
              name: this.Sofer.name,
              phone: this.Sofer.phone,
              email: this.Sofer.email,
              has_unread: false,
              unread_count: 0
            }
          })
        }, 500)
      })
    }
  },
  watch: {
    driversLocationsFiltered: function (results) {
      if (results.length === 1) {
        this.mapCenter.lat = results[0].lat
        this.mapCenter.long = results[0].long
      }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped>

</style>
