import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
// import HomePage from '../views/Website/Home.vue'
import OperatorDashboard from '../views/Operator/Dashboard.vue'
import DeliveryDashboard from '../views/Delivery/Dashboard.vue'
import store from '@/store'
import Login from '@/views/Login'
import Logout from '@/components/common/Logout'
import DriversMap from '@/components/dashboard/Operator/DriversMap'
import OrderView from '@/components/dashboard/Operator/OrderView'
import DeliveryOrderView from '@/components/dashboard/Delivery/DeliveryOrderView'
import DriverPrivacy from '@/Pages/Privacy'
import AppSite from '../views/Website/App'
import Pro from '../views/Website/Pro'
import DriverReports from '../views/Driver/Reports'
import AppDownload from '@/Pages/Download'
import SignUp from '@/Pages/Company/SignUp'
import OperatorDashboardV2 from '@/components/dashboard/OperatorDashboardV2'
import OrdersList from '@/components/dashboard/Operator/Orders/v2/OrdersList'
import LiveMap from '@/views/LiveMap'
import Simulator from '@/components/developer/Simulator'
import Builder from '@/components/settings/builder/Builder'

Vue.use(VueRouter)

var title = 'TAXI'

const routes = [
  {
    path: '/business/enroll',
    name: 'SignUp',
    component: SignUp,
    meta: {
      title: 'Înrolare OGO'
    }
  },
  {
    path: '/pro',
    name: 'Pro',
    component: Pro,
    meta: {
      title: 'Descarcă OGO DRIVER PRO',
      hideNav: true
    }
  },
  {
    path: '/download',
    name: 'AppDownload',
    component: AppDownload,
    meta: {
      title: 'Descarcă OGO TAXI',
      hideNav: true
    }
  },
  {
    path: '/app',
    name: 'AppInfo',
    component: AppSite,
    meta: {
      title: 'Informații aplicație OGO TAXI',
      hideNav: true
    }
  },
  {
    path: '/live',
    name: 'Live',
    component: LiveMap,
    meta: {
      title: 'Live map',
      hideNav: true
    }
  },
  {
    path: '/driver/privacy',
    name: 'Politica de confidentialitate - Sofer',
    component: DriverPrivacy,
    meta: {
      title: 'Politica de confidentialitate - Sofer'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: title
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/',
    name: 'Home',
    component: SignUp,
    meta: {
      title: 'Home',
      authRequired: false
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      authRequired: true
    }
  },
  {
    path: '/operator',
    component: OperatorDashboard,
    meta: {
      title: 'Operator Dashboard',
      authRequired: true
    },
    children: [
      {
        path: 'map',
        name: 'OperatorDriversMap',
        component: DriversMap
      },
      {
        path: '',
        redirect: to => {
          return 'order/0'
        }
      },
      {
        path: 'order/:id',
        name: 'OperatorOrderView',
        component: OrderView
      }
    ]
  },
  {
    path: '/driver/reports/:token',
    name: 'DriverReports',
    component: DriverReports,
    meta: {
      title: 'Istoric curse',
      hideNav: true
    }
  },
  {
    path: '/delivery',
    component: DeliveryDashboard,
    meta: {
      title: 'Delivery Dashboard',
      authRequired: true
    },
    children: [
      {
        path: 'order/:id',
        name: 'DeliveryOrderView',
        component: DeliveryOrderView
      }
    ]
  },
  {
    path: '/operator/v2',
    component: OperatorDashboardV2,
    meta: {
      title: 'Operator Dashboard',
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'OrdersList',
        component: OrdersList
      }
    ]
  },
  {
    path: '/users/:type',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/user/:id/:role',
    name: 'UserEdit',
    component: () => import(/* webpackChunkName: "users" */ '@/components/users/UserEdit'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/orders/:type',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/cars',
    name: 'Cars',
    component: () => import(/* webpackChunkName: "cars" */ '@/views/Cars'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import(/* webpackChunkName: "devices" */ '@/views/Devices'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/device/:id',
    name: 'DeviceEdit',
    component: () => import(/* webpackChunkName: "devices" */ '@/views/DeviceEdit'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances',
    name: 'Financiar',
    component: () => import(/* webpackChunkName: "finances" */ '@/views/Finances'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/sold',
    name: 'Sold soferi',
    component: () => import(/* webpackChunkName: "sold" */ '@/components/finances/SoldSoferi'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/transfer',
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "transfer" */ '@/components/finances/Transfer'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/transfer-discount',
    name: 'TransferDiscount',
    component: () => import(/* webpackChunkName: "transfer" */ '@/components/finances/TransferDiscount'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '@/components/finances/History'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/reports',
    name: 'FinanceReports',
    component: () => import(/* webpackChunkName: "finances" */ '@/components/finances/OrderFinanceReports'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/car/:id',
    name: 'CarEdit',
    component: () => import(/* webpackChunkName: "cars" */ '@/components/cars/CarEdit'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/general/General'),
    meta: {
      title: 'Setări sistem',
      authRequired: true
    }
  },
  {
    path: '/settings/polygons',
    name: 'MapPolygons',
    component: () => import(/* webpackChunkName: "cars" */ '@/components/settings/map/MapPolygons'),
    meta: {
      title: 'Setări zone',
      authRequired: true
    }
  },
  {
    path: '/settings/account',
    name: 'AccountSettings',
    component: () => import(/* webpackChunkName: "account" */ '@/components/settings/account/AccountSettings'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/content',
    name: 'Content',
    component: () => import(/* webpackChunkName: "content" */ '@/components/content/ContentList'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/discounts',
    component: () => import(/* webpackChunkName: "discounts" */ '@/components/discounts/DiscountsList'),
    meta: {
      title: 'Discounts',
      authRequired: true
    }
  },
  {
    path: '/discount/:id',
    component: () => import(/* webpackChunkName: "discounts" */ '@/components/discounts/DiscountEdit'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/components/reports/ReportsList'),
    meta: {
      title: 'Reports',
      authRequired: true
    },
    children: [
      {
        path: '/reports/orders',
        name: 'OrdersReport',
        component: () => import(/* webpackChunkName: "reports" */ '@/components/reports/OrdersReport'),
        meta: {
          authRequired: true
        }
      }
    ]
  },
  {
    path: '/builder',
    component: Builder,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/page/:url',
    component: () => import(/* webpackChunkName: "discounts" */ '@/views/Website/Pages/Page'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/documentation',
    component: () => import(/* webpackChunkName: "discounts" */ '@/documentation/control/DocumentationControl'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/documentation/:url',
    component: () => import(/* webpackChunkName: "discounts" */ '@/documentation/control/DocumentationControl'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/docs/:url',
    component: () => import(/* webpackChunkName: "discounts" */ '@/documentation/control/DocumentationView'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/simulator',
    component: Simulator,
    meta: {
      authRequired: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title === undefined) {
    document.title = 'OGO Dashboard'
  } else {
    document.title = to.meta.title
  }
  if (to.meta.authRequired && !store.getters['auth/isUserLogged']) {
    next('/login')
  } else {
    next()
  }
})

export default router
