import JsSIP from 'jssip'
import store from '@/store/index'
import router from '@/router/index'
import { format } from '@/services/sipPhoneFormatter'

let ua = null

const answerOptions = {
  mediaConstraints: {
    audio: true,
    video: false
  },
  pcConfig: {
    iceServers: [
      { urls: ['stun:stun.l.google.com:19302'] }
    ]
  },
  rtcAnswerConstraints: {
    offerToReceiveAudio: true
  },
  rtcOfferConstraints: {
    offerToReceiveAudio: true
  }
}

const holdUnholdOptions = {
  useUpdate: true
}

function init (config) {
  if (store.state.auth.meta === null) {
    alert('User meta not found')
    return
  }

  if (store.state.auth.meta.sipUrl === '') {
    alert('Sip URL not set. Calls not available.')
  }

  if (store.state.auth.meta.sipPassword === '') {
    alert('Sip password not set. Calls not available.')
  }

  if (store.state.auth.meta.sipWs === '') {
    alert('Sip WebSocket not set. Calls not available.')
  }

  // JsSIP.debug.enabled('JsSIP:*')
  const socket = new JsSIP.WebSocketInterface(store.state.auth.meta.sipWs)
  const configuration = {
    sockets: [socket],
    uri: store.state.auth.meta.sipUrl,
    password: store.state.auth.meta.sipPassword
  }

  ua = new JsSIP.UA(configuration)
  ua.start()

  if (typeof config.onConnected !== 'undefined') {
    ua.on('connected', config.onConnected)
  }

  if (typeof config.onDisconnect !== 'undefined') {
    ua.on('disconnected', config.onDisconnect)
  }

  if (typeof config.onRegistrationFailed !== 'undefined') {
    ua.on('registrationFailed', config.onRegistrationFailed)
  }

  ua.on('newRTCSession', function (data) {
    const session = data.session

    console.log('newRTCSession', session)

    if (session.direction === 'incoming') {
      session.on('accepted', function (e) {
        if (router.currentRoute.name !== 'OperatorOrderView' || (router.currentRoute.name === 'OperatorOrderView' && router.currentRoute.params?.id !== '0')) {
          // router.push({ name: 'OperatorOrderView', params: { id: 0 } })
        }
      })
      session.on('confirmed', function (event) {
        const phoneNumber = format(event.ack.from._uri._user ? event.ack.from._uri._user : event.ack.from._display_name).toLowerCase()
        const sipCallerId = event.ack.from._uri._user ? event.ack.from._uri._user : event.ack.from._display_name
        store.dispatch('dispatcherDashboard/acceptCall', {
          phoneNumber: phoneNumber,
          sipCallerId: sipCallerId
        })
          .then((response) => {
            store.state.sip.calls[phoneNumber].orderId = response.data.id
            store.state.dispatcherDashboard.orderInView = response.data
            router.push({ name: 'OperatorOrderView', params: { id: response.data.id } })

            // unshift order to live orders
            try {
              store.state.dispatcherDashboard.ordersList.unshift({
                id: response.data.id,
                created_at: response.data.created_at,
                state: response.data.state,
                waiting_driver_time: response.data.waiting_driver_time,
                in_pending_queue: response.data.in_pending_queue,
                driver_id: response.data.driver_id,
                dispatcher_id: response.data.dispatcher_id,
                always_in_call: response.data.always_in_call,
                created_by_company_id: response.data.created_by_company_id,
                processed_by_company_id: response.data.processed_by_company_id,
                address: response.data.places[0] ? response.data.places[0].name : '',
                client_phone: response.data.client.phone,
                car_indicativ: response.data.driver?.identifier,
                call_started_at: response.data.call.started_at,
                call_ended_at: response.data.call.ended_at,
                call_state: response.data.call.state,
                dispatcher_order: 0
              })
            } catch (e) {}
          })
      })

      if (typeof config.onCallEnded !== 'undefined') {
        session.on('ended', config.onCallEnded)
      }

      if (typeof config.onCallFailed !== 'undefined') {
        session.on('failed', config.onCallFailed)
      }

      session.on('replaces', (e) => {
      })

      session.on('peerconnection', (e) => {
        e.peerconnection.ontrack = (ev) => {
          const remoteAudio = document.getElementById('sip-audio')
          remoteAudio.srcObject = ev.streams[0]
          remoteAudio.play()
        }
      })

      if (typeof config.onNewCall !== 'undefined') {
        config.onNewCall(format(session.remote_identity.uri.user), session)
      }
    }
  })

  return ua
}

export default {
  init,
  answerOptions,
  holdUnholdOptions
}
