<template>
  <div>
    <template v-if="orderCallSession">
      <b-button :disabled="callInUpdate" @click="holdCall" v-if="!orderCallSession.isOnHold().local" size="sm" variant="warning" class="ml-1 sip-hold-unhold-control">
        <b-icon icon="pause-circle"></b-icon>
      </b-button>
      <b-button :disabled="callInUpdate" @click="unholdCall" v-if="orderCallSession.isOnHold().local" size="sm" variant="success" class="ml-2 sip-hold-unhold-control">
        <b-icon icon="play-circle"></b-icon>
      </b-button>
      <b-button :disabled="callInUpdate" @click="endCall" size="sm" variant="danger" class="ml-2 sip-end-control" :class="{'active-call': !orderCallSession.isOnHold().local}">
        <b-icon icon="stop-circle"></b-icon>
      </b-button>
    </template>
  </div>
</template>

<script>
import jsSip from '@/services/jsSip'
import config from '@/config'

export default {
  name: 'OrderListItemSipControls',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callInUpdate: false
    }
  },
  computed: {
    orderCallSession () {
      if (typeof this.$store.state.sip.calls[this.clientPhone] !== 'undefined') {
        return this.$store.state.sip.calls[this.clientPhone].callSession
      }
      return null
    },
    clientPhone () {
      return this.order.client.phone
    }
  },
  methods: {
    holdCall () {
      this.callInUpdate = true
      this.$store.state.sip.calls[this.clientPhone].callSession.hold(jsSip.holdUnholdOptions)
      this.callInUpdate = false
    },
    unholdCall () {
      this.callInUpdate = true
      for (const phone in this.$store.state.sip.calls) {
        if (phone !== this.clientPhone && this.$store.state.sip.calls[phone].callSession._is_confirmed && this.$store.state.sip.calls[phone].callSession.isOnHold().local === false) {
          this.$store.state.sip.calls[phone].callSession.hold(jsSip.holdUnholdOptions)
        }
      }
      this.$store.state.sip.calls[this.clientPhone].callSession.unhold(jsSip.holdUnholdOptions)
      this.callInUpdate = false
    },
    endCall () {
      this.callInUpdate = true
      this.$axios.patch(config.baseApiUrl + '/api/calls/cancel/', {
        phone_number: this.clientPhone
      })
        .then(() => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
          this.$store.commit('dispatcherDashboard/setCallEndedForOrder', {
            orderId: this.order.id
          })
          const session = this.$store.state.sip.calls[this.clientPhone].callSession
          this.$store.commit('sip/removeCall', {
            phoneNumber: this.clientPhone
          })
          session.terminate()
        })
        .catch(() => {
        })
        .then(() => {
          this.callInUpdate = false
        })
    }
  }
}
</script>

<style scoped>

</style>
