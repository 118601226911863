<template>
  <div>
    <b-container class="py-3 my-0" fluid>
        <h5>Bine ai venit!</h5>
          <div class="dashboardControls">
            <b-row>
              <b-col md="12">
                <div class="row">
                  <b-col class="my-3" cols="6" md="2">
                    <b-link to="/operator" class="statsHref">
                      <b-card :title="statistics.ordersTodayCount.toString()">
                        <b-card-text>Comenzi azi</b-card-text>
                      </b-card>
                    </b-link>
                  </b-col>
                  <b-col class="my-3" cols="6" md="2">
                    <b-link to="/users/driver" class="statsHref">
                      <b-card :title="statistics.driversCount.toString()">
                        <b-card-text>Total șoferi</b-card-text>
                      </b-card>
                    </b-link>
                  </b-col>
                  <b-col class="my-3" cols="6" md="2">
                    <b-link to="/users/client" class="statsHref">
                      <b-card  :title="statistics.clientsCount.toString()">
                        <b-card-text>Total clienți</b-card-text>
                      </b-card>
                    </b-link>
                  </b-col>
                  <b-col class="my-3" cols="6" md="2">
                    <b-link to="/reports/orders" class="statsHref">
                      <b-card :title="statistics.ordersTotalCount.toString()">
                        <b-card-text>Comenzi total</b-card-text>
                      </b-card>
                    </b-link>
                  </b-col>
                  <b-col class="my-3" cols="6" md="2">
                    <b-card :title="statistics.announcesCount.toString()" border-variant="warning">
                      <b-card-text>Anunțuri</b-card-text>
                    </b-card>
                  </b-col>
                  <b-col class="my-3" cols="6" md="2">
                    <b-card :title="statistics.incidentsCount.toString()" border-variant="danger">
                      <b-card-text>Incidente</b-card-text>
                    </b-card>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col md="12">
              <b-card>
                <div id="chart">
                  <div class="toolbar">
                    <b-button size="sm" variant="outline-primary" class="mr-2" id="one_month"
                        @click="updateData('one_month')" :class="{active: selection==='one_month'}"
                        >
                      1 lună
                    </b-button>
                    <b-button size="sm" variant="outline-primary" class="mr-2" id="six_months"
                        @click="updateData('six_months')" :class="{active: selection==='six_months'}"
                        >
                      6 luni
                    </b-button>
                    <b-button size="sm" variant="outline-primary" class="mr-2" id="one_year"
                        @click="updateData('one_year')" :class="{active: selection==='one_year'}"
                        >
                      Anual
                    </b-button>
                    <b-button size="sm" variant="outline-primary" class="mr-2" id="ytd"
                        @click="updateData('ytd')" :class="{active: selection==='ytd'}"
                        >
                      YTD
                    </b-button>
                    <b-button size="sm" variant="outline-primary" class="mr-2" id="all"
                        @click="updateData('all')" :class="{active: selection==='all'}"
                        >
                      Tot
                    </b-button>
                  </div>
                  <div id="chart-timeline">
                    <apexchart type="area" height="300" ref="chart" :options="chartOptions" :series="series"></apexchart>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>

          <div class="my-3">
            <b-row align-self="stretch">
              <b-col md="6" class="d-none d-md-block">
                <h5 class="d-flex">
                  Zona de acoperire
                  <b-button class="ml-auto" to="/settings/polygons" size="sm" variant="light">
                    Modifică zonele <b-icon icon="pen"></b-icon>
                  </b-button>
                  </h5>
                  <div class="clearfix"></div>
                <GmapMap
                  ref="map"
                  :center="this.$store.getters['auth/loggedUser'].id !== 1 ? mapCenter : { lat: 45.9442858, lng: 25.0094303 }"
                  :zoom="this.$store.getters['auth/loggedUser'].id !== 1 ? 13 : 7"
                  style="width: 100%; height: calc(100% - 40px); border: 1px solid #bdc1c6; border-radius:5px;"
                  :key="''"
                  :options="MapStyle"
                >
                  <gmap-polygon class="mapPoly" v-for="poly in polygonsGoogleList"
                  v-bind:key="'polygon-' + poly.id"
                  :paths="poly.paths"
                  :editable="false"
                  :options="{fillColor:  '#000', fillOpacity: 0.5,strokeOpacity: 1,strokeWeight: 1, strokeColor: '#fff'}"
                  :draggable="false"
                  ref="polygon"
                ></gmap-polygon>
              </GmapMap>
              </b-col>
              <b-col md="6">
                <h5 class="d-flex">
                  Comenzi recente
                  <b-button class="ml-auto" @click="getOrdersBtn" size="sm" variant="light">
                    <b-icon icon="arrow-repeat"></b-icon>
                    <span>{{ loadStatus }}</span>
                  </b-button>
                </h5>
                <div>
                  <b-list-group>
                    <b-list-group-item button  @click="onRowClicked(item.orderId)" class="d-flex align-items-center" v-for="item in orders" :key="item.orderId">
                      {{ item.orderId }} - {{  new Date(item.callDuration * 60000).toISOString().substring(11, 16) }} -
                      {{ toLocalDate(item.orderDate) }}
                      - {{ item.placeName ? item.placeName : 'Adresă nespecificată' }}
                      <b-badge v-if="item.state === 'assigned'" variant="warning" class="ml-auto" >Alocat</b-badge>
                      <b-badge v-if="item.state === 'finished'" variant="success" class="ml-auto" >Finalizat</b-badge>
                      <b-badge v-if="item.state === 'canceled'" variant="danger" class="ml-auto" >Anulat</b-badge>
                      <b-badge v-if="item.state === 'new' || item.state === 'created' || item.state === 'pending'" variant="info" class="ml-auto" >Nou</b-badge>
                      <b-badge class="ml-3" variant="dark">{{ item.indicativ }}</b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-col>
            </b-row>
          </div>
    </b-container>
  </div>
</template>

<script>
import config from '@/config'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import dayjs from 'dayjs'

export default {
  name: 'DispatcherDashboard',
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      mapCenter: this.$store.getters['auth/companyLocation'],
      MapStyle: {
        mapTypeControl: false,
        streetViewControl: false
      },
      polygons: [],
      polygonsRawList: [],
      loadStatus: null,
      statistics: {
        driversCount: 0,
        driversOnlineCount: 0,
        driversBusyCount: 0,
        clientsCount: 0,
        ordersTodayCount: 0,
        ordersTotalCount: 0,
        announcesCount: 0,
        incidentsCount: 0
      },
      orders: [],
      meta: null,
      commonStatisticsUpdateInterval: null,
      series: [{
        name: 'Comenzi online',
        data: [
          [1327359600000, 30.95],
          [1327446000000, 31.34],
          [1327532400000, 31.18],
          [1327618800000, 31.05],
          [1327878000000, 31.00],
          [1327964400000, 30.95],
          [1328050800000, 31.24],
          [1328137200000, 31.29],
          [1328223600000, 31.85],
          [1328482800000, 31.86],
          [1328569200000, 32.28],
          [1328655600000, 32.10],
          [1328742000000, 32.65],
          [1328828400000, 32.21],
          [1329087600000, 32.35],
          [1329174000000, 32.44],
          [1329260400000, 32.46],
          [1329346800000, 32.86],
          [1329433200000, 32.75],
          [1329778800000, 32.54],
          [1329865200000, 32.33],
          [1329951600000, 32.97],
          [1330038000000, 33.41],
          [1330297200000, 33.27],
          [1330383600000, 33.27],
          [1330470000000, 32.89],
          [1330556400000, 33.10],
          [1330642800000, 33.73],
          [1330902000000, 33.22],
          [1330988400000, 31.99],
          [1331074800000, 32.41],
          [1331161200000, 33.05],
          [1331247600000, 33.64],
          [1331506800000, 33.56],
          [1331593200000, 34.22],
          [1331679600000, 33.77],
          [1331766000000, 34.17],
          [1331852400000, 33.82],
          [1332111600000, 34.51],
          [1332198000000, 33.16],
          [1332284400000, 33.56],
          [1332370800000, 33.71],
          [1332457200000, 33.81],
          [1332712800000, 34.40],
          [1332799200000, 34.63],
          [1332885600000, 34.46],
          [1332972000000, 34.48],
          [1333058400000, 34.31],
          [1333317600000, 34.70],
          [1333404000000, 34.31],
          [1333490400000, 33.46],
          [1333576800000, 33.59],
          [1333922400000, 33.22],
          [1334008800000, 32.61],
          [1334095200000, 33.01],
          [1334181600000, 33.55],
          [1334268000000, 33.18],
          [1334527200000, 32.84],
          [1334613600000, 33.84],
          [1334700000000, 33.39],
          [1334786400000, 32.91],
          [1334872800000, 33.06],
          [1335132000000, 32.62],
          [1335218400000, 32.40],
          [1335304800000, 33.13],
          [1335391200000, 33.26],
          [1335477600000, 33.58],
          [1335736800000, 33.55],
          [1335823200000, 33.77],
          [1335909600000, 33.76],
          [1335996000000, 33.32],
          [1336082400000, 32.61],
          [1336341600000, 32.52],
          [1336428000000, 32.67],
          [1336514400000, 32.52],
          [1336600800000, 31.92],
          [1336687200000, 32.20],
          [1336946400000, 32.23],
          [1337032800000, 32.33],
          [1337119200000, 32.36],
          [1337205600000, 32.01],
          [1337292000000, 31.31],
          [1337551200000, 32.01],
          [1337637600000, 32.01],
          [1337724000000, 32.18],
          [1337810400000, 31.54],
          [1337896800000, 31.60],
          [1338242400000, 32.05],
          [1338328800000, 31.29],
          [1338415200000, 31.05],
          [1338501600000, 29.82],
          [1338760800000, 30.31],
          [1338847200000, 30.70],
          [1338933600000, 31.69],
          [1339020000000, 31.32],
          [1339106400000, 31.65],
          [1339365600000, 31.13],
          [1339452000000, 31.77],
          [1339538400000, 31.79],
          [1339624800000, 31.67],
          [1339711200000, 32.39],
          [1339970400000, 32.63],
          [1340056800000, 32.89],
          [1340143200000, 31.99],
          [1340229600000, 31.23],
          [1340316000000, 31.57],
          [1340575200000, 30.84],
          [1340661600000, 31.07],
          [1340748000000, 31.41],
          [1340834400000, 31.17],
          [1340920800000, 32.37],
          [1341180000000, 32.19],
          [1341266400000, 32.51],
          [1341439200000, 32.53],
          [1341525600000, 31.37],
          [1341784800000, 30.43],
          [1341871200000, 30.44],
          [1341957600000, 30.20],
          [1342044000000, 30.14],
          [1342130400000, 30.65],
          [1342389600000, 30.40],
          [1342476000000, 30.65],
          [1342562400000, 31.43],
          [1342648800000, 31.89],
          [1342735200000, 31.38],
          [1342994400000, 30.64],
          [1343080800000, 30.02],
          [1343167200000, 30.33],
          [1343253600000, 30.95],
          [1343340000000, 31.89],
          [1343599200000, 31.01],
          [1343685600000, 30.88],
          [1343772000000, 30.69],
          [1343858400000, 30.58],
          [1343944800000, 32.02],
          [1344204000000, 32.14],
          [1344290400000, 32.37],
          [1344376800000, 32.51],
          [1344463200000, 32.65],
          [1344549600000, 32.64],
          [1344808800000, 32.27],
          [1344895200000, 32.10],
          [1344981600000, 32.91],
          [1345068000000, 33.65],
          [1345154400000, 33.80],
          [1345413600000, 33.92],
          [1345500000000, 33.75],
          [1345586400000, 33.84],
          [1345672800000, 33.50],
          [1345759200000, 32.26],
          [1346018400000, 32.32],
          [1346104800000, 32.06],
          [1346191200000, 31.96],
          [1346277600000, 31.46],
          [1346364000000, 31.27],
          [1346709600000, 31.43],
          [1346796000000, 32.26],
          [1346882400000, 32.79],
          [1346968800000, 32.46],
          [1347228000000, 32.13],
          [1347314400000, 32.43],
          [1347400800000, 32.42],
          [1347487200000, 32.81],
          [1347573600000, 33.34],
          [1347832800000, 33.41],
          [1347919200000, 32.57],
          [1348005600000, 33.12],
          [1348092000000, 34.53],
          [1348178400000, 33.83],
          [1348437600000, 33.41],
          [1348524000000, 32.90],
          [1348610400000, 32.53],
          [1348696800000, 32.80],
          [1348783200000, 32.44],
          [1349042400000, 32.62],
          [1349128800000, 32.57],
          [1349215200000, 32.60],
          [1349301600000, 32.68],
          [1349388000000, 32.47],
          [1349647200000, 32.23],
          [1349733600000, 31.68],
          [1349820000000, 31.51],
          [1349906400000, 31.78],
          [1349992800000, 31.94],
          [1350252000000, 32.33],
          [1350338400000, 33.24],
          [1350424800000, 33.44],
          [1350511200000, 33.48],
          [1350597600000, 33.24],
          [1350856800000, 33.49],
          [1350943200000, 33.31],
          [1351029600000, 33.36],
          [1351116000000, 33.40],
          [1351202400000, 34.01],
          [1351638000000, 34.02],
          [1351724400000, 34.36],
          [1351810800000, 34.39],
          [1352070000000, 34.24],
          [1352156400000, 34.39],
          [1352242800000, 33.47],
          [1352329200000, 32.98],
          [1352415600000, 32.90],
          [1352674800000, 32.70],
          [1352761200000, 32.54],
          [1352847600000, 32.23],
          [1352934000000, 32.64],
          [1353020400000, 32.65],
          [1353279600000, 32.92],
          [1353366000000, 32.64],
          [1353452400000, 32.84],
          [1353625200000, 33.40],
          [1353884400000, 33.30],
          [1353970800000, 33.18],
          [1354057200000, 33.88],
          [1354143600000, 34.09],
          [1354230000000, 34.61],
          [1354489200000, 34.70],
          [1354575600000, 35.30],
          [1354662000000, 35.40],
          [1354748400000, 35.14],
          [1354834800000, 35.48],
          [1355094000000, 35.75],
          [1355180400000, 35.54],
          [1355266800000, 35.96],
          [1355353200000, 35.53],
          [1355439600000, 37.56],
          [1355698800000, 37.42],
          [1355785200000, 37.49],
          [1355871600000, 38.09],
          [1355958000000, 37.87],
          [1356044400000, 37.71],
          [1356303600000, 37.53],
          [1356476400000, 37.55],
          [1356562800000, 37.30],
          [1356649200000, 36.90],
          [1356908400000, 37.68],
          [1357081200000, 38.34],
          [1357167600000, 37.75],
          [1357254000000, 38.13],
          [1357513200000, 37.94],
          [1357599600000, 38.14],
          [1357686000000, 38.66],
          [1357772400000, 38.62],
          [1357858800000, 38.09],
          [1358118000000, 38.16],
          [1358204400000, 38.15],
          [1358290800000, 37.88],
          [1358377200000, 37.73],
          [1358463600000, 37.98],
          [1358809200000, 37.95],
          [1358895600000, 38.25],
          [1358982000000, 38.10],
          [1359068400000, 38.32],
          [1359327600000, 38.24],
          [1359414000000, 38.52],
          [1359500400000, 37.94],
          [1359586800000, 37.83],
          [1359673200000, 38.34],
          [1359932400000, 38.10],
          [1360018800000, 38.51],
          [1360105200000, 38.40],
          [1360191600000, 38.07],
          [1360278000000, 39.12],
          [1360537200000, 38.64],
          [1360623600000, 38.89],
          [1360710000000, 38.81],
          [1360796400000, 38.61],
          [1360882800000, 38.63],
          [1361228400000, 38.99],
          [1361314800000, 38.77],
          [1361401200000, 38.34],
          [1361487600000, 38.55],
          [1361746800000, 38.11],
          [1361833200000, 38.59],
          [1361919600000, 39.60]
        ]
      }, {
        name: 'Comenzi telefonic',
        data: [
          [1327359600000, 40.95],
          [1327446000000, 41.34],
          [1327532400000, 41.18],
          [1327618800000, 41.05],
          [1327878000000, 41.00],
          [1327964400000, 40.95],
          [1328050800000, 41.24],
          [1328137200000, 41.29],
          [1328223600000, 41.85],
          [1328482800000, 41.86],
          [1328569200000, 42.28],
          [1328655600000, 42.10],
          [1328742000000, 42.65],
          [1328828400000, 42.21],
          [1329087600000, 42.35],
          [1329174000000, 42.44],
          [1329260400000, 42.46],
          [1329346800000, 42.86],
          [1329433200000, 42.75],
          [1329778800000, 42.54],
          [1329865200000, 42.33],
          [1329951600000, 42.97],
          [1330038000000, 43.41],
          [1330297200000, 43.27],
          [1330383600000, 43.27],
          [1330470000000, 42.89],
          [1330556400000, 43.10],
          [1330642800000, 43.73],
          [1330902000000, 43.22],
          [1330988400000, 41.99],
          [1331074800000, 42.41],
          [1331161200000, 43.05],
          [1331247600000, 43.64],
          [1331506800000, 43.56],
          [1331593200000, 44.22],
          [1331679600000, 43.77],
          [1331766000000, 44.17],
          [1331852400000, 43.82],
          [1332111600000, 44.51],
          [1332198000000, 43.16],
          [1332284400000, 43.56],
          [1332370800000, 43.71],
          [1332457200000, 43.81],
          [1332712800000, 44.40],
          [1332799200000, 44.63],
          [1332885600000, 44.46],
          [1332972000000, 44.48],
          [1333058400000, 44.31],
          [1333317600000, 44.70],
          [1333404000000, 44.31],
          [1333490400000, 43.46],
          [1333576800000, 43.59],
          [1333922400000, 43.22],
          [1334008800000, 42.61],
          [1334095200000, 43.01],
          [1334181600000, 43.55],
          [1334268000000, 43.18],
          [1334527200000, 42.84],
          [1334613600000, 43.84],
          [1334700000000, 43.39],
          [1334786400000, 42.91],
          [1334872800000, 43.06],
          [1335132000000, 42.62],
          [1335218400000, 42.40],
          [1335304800000, 43.13],
          [1335391200000, 43.26],
          [1335477600000, 43.58],
          [1335736800000, 43.55],
          [1335823200000, 43.77],
          [1335909600000, 43.76],
          [1335996000000, 43.32],
          [1336082400000, 42.61],
          [1336341600000, 42.52],
          [1336428000000, 42.67],
          [1336514400000, 42.52],
          [1336600800000, 41.92],
          [1336687200000, 42.20],
          [1336946400000, 42.23],
          [1337032800000, 42.33],
          [1337119200000, 42.36],
          [1337205600000, 42.01],
          [1337292000000, 41.31],
          [1337551200000, 42.01],
          [1337637600000, 42.01],
          [1337724000000, 42.18],
          [1337810400000, 41.54],
          [1337896800000, 41.60],
          [1338242400000, 42.05],
          [1338328800000, 41.29],
          [1338415200000, 41.05],
          [1338501600000, 29.82],
          [1338760800000, 40.31],
          [1338847200000, 40.70],
          [1338933600000, 41.69],
          [1339020000000, 41.32],
          [1339106400000, 41.65],
          [1339365600000, 41.13],
          [1339452000000, 41.77],
          [1339538400000, 41.79],
          [1339624800000, 41.67],
          [1339711200000, 42.39],
          [1339970400000, 42.63],
          [1340056800000, 42.89],
          [1340143200000, 41.99],
          [1340229600000, 41.23],
          [1340316000000, 41.57],
          [1340575200000, 40.84],
          [1340661600000, 41.07],
          [1340748000000, 41.41],
          [1340834400000, 41.17],
          [1340920800000, 42.37],
          [1341180000000, 42.19],
          [1341266400000, 42.51],
          [1341439200000, 42.53],
          [1341525600000, 41.37],
          [1341784800000, 40.43],
          [1341871200000, 40.44],
          [1341957600000, 40.20],
          [1342044000000, 40.14],
          [1342130400000, 40.65],
          [1342389600000, 40.40],
          [1342476000000, 40.65],
          [1342562400000, 41.43],
          [1342648800000, 41.89],
          [1342735200000, 41.38],
          [1342994400000, 40.64],
          [1343080800000, 40.02],
          [1343167200000, 40.33],
          [1343253600000, 40.95],
          [1343340000000, 41.89],
          [1343599200000, 41.01],
          [1343685600000, 40.88],
          [1343772000000, 40.69],
          [1343858400000, 40.58],
          [1343944800000, 42.02],
          [1344204000000, 42.14],
          [1344290400000, 42.37],
          [1344376800000, 42.51],
          [1344463200000, 42.65],
          [1344549600000, 42.64],
          [1344808800000, 42.27],
          [1344895200000, 42.10],
          [1344981600000, 42.91],
          [1345068000000, 43.65],
          [1345154400000, 43.80],
          [1345413600000, 43.92],
          [1345500000000, 43.75],
          [1345586400000, 43.84],
          [1345672800000, 43.50],
          [1345759200000, 42.26],
          [1346018400000, 42.32],
          [1346104800000, 42.06],
          [1346191200000, 41.96],
          [1346277600000, 41.46],
          [1346364000000, 41.27],
          [1346709600000, 41.43],
          [1346796000000, 42.26],
          [1346882400000, 42.79],
          [1346968800000, 42.46],
          [1347228000000, 42.13],
          [1347314400000, 42.43],
          [1347400800000, 42.42],
          [1347487200000, 42.81],
          [1347573600000, 43.34],
          [1347832800000, 43.41],
          [1347919200000, 42.57],
          [1348005600000, 43.12],
          [1348092000000, 44.53],
          [1348178400000, 43.83],
          [1348437600000, 43.41],
          [1348524000000, 42.90],
          [1348610400000, 42.53],
          [1348696800000, 42.80],
          [1348783200000, 42.44],
          [1349042400000, 42.62],
          [1349128800000, 42.57],
          [1349215200000, 42.60],
          [1349301600000, 42.68],
          [1349388000000, 42.47],
          [1349647200000, 42.23],
          [1349733600000, 41.68],
          [1349820000000, 41.51],
          [1349906400000, 41.78],
          [1349992800000, 41.94],
          [1350252000000, 42.33],
          [1350338400000, 43.24],
          [1350424800000, 43.44],
          [1350511200000, 43.48],
          [1350597600000, 43.24],
          [1350856800000, 43.49],
          [1350943200000, 43.31],
          [1351029600000, 43.36],
          [1351116000000, 43.40],
          [1351202400000, 44.01],
          [1351638000000, 44.02],
          [1351724400000, 44.36],
          [1351810800000, 44.39],
          [1352070000000, 44.24],
          [1352156400000, 44.39],
          [1352242800000, 43.47],
          [1352329200000, 42.98],
          [1352415600000, 42.90],
          [1352674800000, 42.70],
          [1352761200000, 42.54],
          [1352847600000, 42.23],
          [1352934000000, 42.64],
          [1353020400000, 42.65],
          [1353279600000, 42.92],
          [1353366000000, 42.64],
          [1353452400000, 42.84],
          [1353625200000, 43.40],
          [1353884400000, 43.30],
          [1353970800000, 43.18],
          [1354057200000, 43.88],
          [1354143600000, 44.09],
          [1354230000000, 44.61],
          [1354489200000, 44.70],
          [1354575600000, 45.30],
          [1354662000000, 45.40],
          [1354748400000, 45.14],
          [1354834800000, 45.48],
          [1355094000000, 45.75],
          [1355180400000, 45.54],
          [1355266800000, 45.96],
          [1355353200000, 45.53],
          [1355439600000, 47.56],
          [1355698800000, 47.42],
          [1355785200000, 47.49],
          [1355871600000, 48.09],
          [1355958000000, 47.87],
          [1356044400000, 47.71],
          [1356303600000, 47.53],
          [1356476400000, 47.55],
          [1356562800000, 47.30],
          [1356649200000, 46.90],
          [1356908400000, 47.68],
          [1357081200000, 48.34],
          [1357167600000, 47.75],
          [1357254000000, 48.13],
          [1357513200000, 47.94],
          [1357599600000, 48.14],
          [1357686000000, 48.66],
          [1357772400000, 48.62],
          [1357858800000, 48.09],
          [1358118000000, 48.16],
          [1358204400000, 48.15],
          [1358290800000, 47.88],
          [1358377200000, 47.73],
          [1358463600000, 47.98],
          [1358809200000, 47.95],
          [1358895600000, 48.25],
          [1358982000000, 48.10],
          [1359068400000, 48.32],
          [1359327600000, 48.24],
          [1359414000000, 48.52],
          [1359500400000, 47.94],
          [1359586800000, 47.83],
          [1359673200000, 48.34],
          [1359932400000, 48.10],
          [1360018800000, 48.51],
          [1360105200000, 48.40],
          [1360191600000, 48.07],
          [1360278000000, 49.12],
          [1360537200000, 48.64],
          [1360623600000, 48.89],
          [1360710000000, 48.81],
          [1360796400000, 48.61],
          [1360882800000, 48.63],
          [1361228400000, 48.99],
          [1361314800000, 48.77],
          [1361401200000, 48.34],
          [1361487600000, 48.55],
          [1361746800000, 48.11],
          [1361833200000, 48.59],
          [1361919600000, 49.60]
        ]
      }],
      chartOptions: {
        chart: {
          id: 'area-datetime',
          type: 'area',
          height: 350,
          zoom: {
            autoScaleYaxis: true
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow'
        },
        xaxis: {
          type: 'datetime',
          min: new Date('01 Mar 2012').getTime(),
          tickAmount: 6
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        }
      },
      selection: 'six_months'
    }
  },
  computed: {

    polygonsGoogleList () {
      return this.polygonsRawList.map((item) => {
        const itemNew = Object.assign({}, item)
        itemNew.paths = []
        item.paths.forEach((it) => {
          itemNew.paths.push(new window.google.maps.LatLng(it.lat, it.long))
        })
        return itemNew
      })
    }
  },
  methods: {
    getSavedPolygons () {
      return Vue.axios.get(config.baseApiUrl + '/api/polygons')
        .then((response) => {
          this.polygonsRawList = response.data.data.map((polygon) => {
            polygon.isVisible = true
            return polygon
          })
        })
    },
    onRowClicked (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    },
    getOrders () {
      this.$axios.get(config.baseApiUrl + '/api/orders-for-report?per_page=7')
        .then((response) => {
          this.orders = response.data.data
        })
    },
    getOrdersBtn () {
      this.getOrders()
      this.loadStatus = ' ... actualizare'
      setTimeout(() => {
        this.loadStatus = ''
      }, 200)
    },
    updateData: function (timeline) {
      this.selection = timeline
      switch (timeline) {
        case 'one_month':
          this.$refs.chart.zoomX(
            new Date('28 Jan 2013').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'six_months':
          this.$refs.chart.zoomX(
            new Date('27 Sep 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'one_year':
          this.$refs.chart.zoomX(
            new Date('27 Feb 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'ytd':
          this.$refs.chart.zoomX(
            new Date('01 Jan 2013').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'all':
          this.$refs.chart.zoomX(
            new Date('23 Jan 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        default:
      }
    },
    getStatistics () {
      this.$axios.get(config.baseApiUrl + '/api/statistics/common')
        .then((response) => {
          this.statistics = response.data
        })
    },
    toLocalDate (value) {
      return dayjs(value + 'Z').local().format('DD.MM.YYYY HH:mm')
    }
  },
  watch: {
    props: function (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    }
  },
  mounted () {
    this.getSavedPolygons()
    this.getOrders()
    this.getStatistics()
    this.commonStatisticsUpdateInterval = setInterval(() => {
      this.getStatistics()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.commonStatisticsUpdateInterval)
  }
}
</script>

<style scoped>
ul.list-group > li > div {
  padding: 5px;
}
ul.list-group > li > div:nth-of-type(1) {
  flex: 1;
}
ul.list-group > li > div:nth-of-type(2) {
  font-size: 12px;
  flex: 5;
}
ul.list-group > li > div:nth-of-type(3) {
  flex: 1;
  text-align: right;
}
</style>
