<template>
  <header class="header">
    <div class="container-fluid">
      <b-row align-v="center">
        <b-col  sm="3" md="auto" class="header-brand mr-auto">
          <div class="d-flex align-items-center justify-content-between">
            <router-link to="/dashboard" class="taxi-brand">
              <img src="@/assets/img/logo.svg" class="d-block mr-1"> TAXI
            </router-link>
          </div>
        </b-col>
        <b-col :class="menu === false ? 'd-none d-md-block header-menu' : 'd-block header-menu'" xs="6"  md="auto">
          <b-nav>
            <b-nav-item to="/dashboard" active>Dashboard</b-nav-item>
            <b-nav-item v-if="this.$store.getters['auth/checkCompanyPackageId'] === 2 || this.$store.getters['auth/checkCompanyPackageId'] === 3" :to="{ name: 'DeliveryOrderView', params: { id: 0 } }" >Comenzi</b-nav-item>
            <b-nav-item v-if="this.$store.getters['auth/checkCompanyPackageId'] === 1" to="/operator" >Operator</b-nav-item>
            <b-nav-item-dropdown
              text="Utilizatori"
            >
              <b-dropdown-item to="/users/dispatcher">Operatori</b-dropdown-item>
              <!-- <b-dropdown-item v-if="this.$store.getters['auth/checkCompanyPackageId'] === 1 && this.$store.getters['auth/checkCompanyPackageId'] === 2" to="/users/driver">{{ this.$store.getters['auth/checkCompanyPackageId'] === 1 ? 'Șoferi' : 'Curieri' }}</b-dropdown-item> -->
              <b-dropdown-item to="/users/driver">Colaboratori</b-dropdown-item>
              <b-dropdown-item to="/users/client">Clienți</b-dropdown-item>
              <b-dropdown-item to="/users/company">Companii</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/cars">Transport</b-dropdown-item>
              <template v-if="$store.state.auth.user.roles.includes('admin')">
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item to="/devices">Devaisuri</b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              text="Financiar"
            >
              <b-dropdown-item to="/finances/sold">Sold șoferi</b-dropdown-item>
              <b-dropdown-item to="/finances/transfer">Transfer balanță</b-dropdown-item>
              <b-dropdown-item to="/finances/transfer-discount">Transfer balanță (discount)</b-dropdown-item>
              <b-dropdown-item to="/discounts">Discounts</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/finances/reports">Rapoarte</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/finances">Setări</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item to="/settings/polygons">Delimitare zone</b-nav-item>
            <b-nav-item to="/reports/orders">Rapoarte</b-nav-item>
            <b-nav-item-dropdown
              text="Sistem"
            >
              <b-dropdown-item to="/builder">Configurare mod de lucru</b-dropdown-item>
              <b-dropdown-item to="/content">Conținut</b-dropdown-item>
              <b-dropdown-item  to="/settings">Setări</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav>
        </b-col>
        <b-col xs="6" sm="6" md="3" class="header-right ml-auto">
          <b-dropdown class=" pull-right" id="top-profile" variant="outline-success" right size="sm" >
            <template #button-content>
              <b-icon icon="person-fill"></b-icon>
            </template>
            <b-dropdown-text v-if="this.$store.getters['auth/loggedUser']">
              <strong>{{ this.$store.getters['auth/loggedUser'].name }}</strong> <br>
              <span>{{ this.$store.getters['auth/loggedUser'].email }}</span>
            </b-dropdown-text>
            <b-dropdown-text style="min-width: 300px;">
              <b-link to="/settings" style="font-size:16px; font-weight: 400; text-decoration:underline">{{ this.$store.getters['auth/loggedUser'].companies[0].name }}</b-link>
            </b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <router-link to="/settings/account" class="dropdown-item">Profil</router-link>
            <router-link to="/settings" class="dropdown-item">Companie</router-link>
            <b-dropdown-divider></b-dropdown-divider>
            <b-link  to="/logout" class="dropdown-item text-danger">Logout</b-link>
          </b-dropdown>
          <MessagesBadge></MessagesBadge>

          <b-tooltip target="top-phone" title="Nu ai niciun apel pierdut"></b-tooltip>
          <b-button class="ml-1 pull-right  d-block d-md-none" size="sm" :variant="menu === true ? 'danger' : 'dark'" @click="menu = !menu">
            <b-icon :icon="menu === true ? 'x' : 'menu-button-wide'"></b-icon>
          </b-button>

          <b-dropdown class="ml-1 pull-right d-none d-md-block call-sound" variant="outline-dark" right size="sm" no-caret>
            <template #button-content>
              <b-icon icon="volume-up"></b-icon>
            </template>
            <b-dropdown-text>
              <p class="m-0 mb-1">
                Opțiuni apel:
              </p>
            </b-dropdown-text>
            <div class="mx-3">
              <b-button size="sm" :variant="getShortkeysType() === 'simple' ? 'dark' : 'outline-dark'" class="my-1 w-100" @click="setCallBtn('simple')">
                Se răspunde cu F1 la apel
              </b-button>
              <b-button size="sm" :variant="getShortkeysType() === 'lines' ? 'dark' : 'outline-dark'" class="my-1 w-100" @click="setCallBtn('lines')">
                Se răspunde cu <u>~</u> la apel
              </b-button>
            </div>
            <b-dropdown-text style="width:300px;" v-for="item in sounds" :key="item.id">
              <b-button-group class="w-100" size="sm" v-if="item.mute">
                <b-button style="width: 250px; text-align:left;" :variant="getSound() === item.src ? 'outline-dark': 'outline-danger'" @click="setSound(item.src)"> <b-icon :icon="getSound() === item.src ? 'circle-fill' : 'circle'"></b-icon>  {{ item.name }} </b-button>
                <b-button  :variant="getSound() === item.src ? 'outline-dark': 'outline-danger'" @click.prevent="playSound(item.src)"> <b-icon :icon="item.playIcon"></b-icon> </b-button>
              </b-button-group>
              <b-button-group class="w-100" size="sm" v-else>
                <b-button style="width: 250px; text-align:left;" :variant="getSound() === item.src ? 'outline-dark': 'light'" @click="setSound(item.src)"> <b-icon :icon="getSound() === item.src ? 'circle-fill' : 'circle'"></b-icon>  {{ item.name }} </b-button>
                <b-button  :variant="getSound() === item.src ? 'outline-dark': 'light'" @click.prevent="playSound(item.src)"> <b-icon :icon="item.playIcon"></b-icon> </b-button>
              </b-button-group>
            </b-dropdown-text>
          </b-dropdown>
          <b-button style="width: 80px" class="ml-3 pull-right d-none d-md-block" size="sm" variant="light">
            {{ timeNow }}
          </b-button>

        </b-col>
      </b-row>
    </div>
    <audio id="jssip-audio"></audio>
    <Chat v-if="$store.state.messages.isOpen"></Chat>
  </header>
</template>

<script>
import NewClientCallToast from './NewClientCallToast'
import Chat from './chat/Chat.vue'
import MessagesBadge from '@/components/common/chat/MessagesBadge'

export default {
  name: 'PosHeader',
  components: {
    MessagesBadge,
    // eslint-disable-next-line vue/no-unused-components
    NewClientCallToast,
    Chat
  },
  data () {
    return {
      menu: false,
      show: false,
      sounds: [
        {
          id: 1,
          name: 'Beep beep',
          src: '/audio/sound_1.wav',
          playIcon: 'play'
        },
        {
          id: 2,
          name: 'Radar ping',
          src: '/audio/sound.wav',
          playIcon: 'play'
        },
        {
          id: 3,
          name: 'Light call',
          src: '/audio/sound_2.wav',
          playIcon: 'play'
        },
        {
          id: 4,
          name: 'Air puff',
          src: '/audio/sound_3.mp3',
          playIcon: 'play'
        },
        {
          id: 5,
          name: 'Bell echo',
          src: '/audio/sound_4.mp3',
          playIcon: 'play'
        },
        {
          id: 6,
          name: 'Ding',
          src: '/audio/sound_5.mp3',
          playIcon: 'play'
        },
        {
          id: 99,
          name: 'Fără sunet',
          src: '/audio/mute.wav',
          playIcon: 'volume-mute',
          mute: true
        }
      ]
    }
  },
  mounted () {
    this.getSound()
    this.getShortkeysType()
  },
  computed: {
    notificationsCount () {
      return this.$store.getters['messages/getConversations'].filter((conversation) => {
        return conversation.has_unread
      }).length
    },
    timeNow () {
      return this.$day(this.$store.state.now).format('HH:mm:ss')
    }
  },

  methods: {
    getShortkeysType () {
      if (localStorage.lineAnswer) {
        return localStorage.lineAnswer
      } else {
        return 'simple'
      }
    },
    getSound () {
      return localStorage.default_sound
    },
    setCallBtn (button) {
      localStorage.setItem('lineAnswer', button)
    },
    setSound (sound) {
      localStorage.setItem('default_sound', sound)
    },
    playSound (sound) {
      if (sound) {
        var audio = new Audio(sound)
        audio.play()
      }
    }
  }
}
</script>

<style scoped>

</style>
