import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/config'
import LoadScript from 'vue-plugin-load-script'
import dayjs from 'dayjs'

Vue.use(LoadScript)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isGoogleApiLoaded: false,
    now: dayjs()
  },
  mutations: {
    initStore (state) {
      if (localStorage.getItem('loggedUser')) {
        state.auth.user = JSON.parse(localStorage.getItem('loggedUser'))
      }
      if (localStorage.getItem('apiToken')) {
        state.auth.apiToken = localStorage.getItem('apiToken')
      }
      if (localStorage.getItem('meta')) {
        state.auth.meta = JSON.parse(localStorage.getItem('meta'))
      }
      this._vm.$loadScript('https://maps.googleapis.com/maps/api/js?key=' + config.googleMapsApiKey + '&libraries=drawing,geometry,places,visualization')
        .then(() => {
          const interval = setInterval(() => {
            if (typeof window.google !== 'undefined') {
              clearInterval(interval)
              state.isGoogleApiLoaded = true
            }
          }, 100)
        })
      setInterval(() => {
        state.now = this._vm.$day()
      }, 1000)
    }
  },
  actions: {
  },
  modules: {
    auth: {
      namespaced: true,
      state: {
        user: null,
        apiToken: null,
        meta: null
      },
      getters: {
        isUserLogged (state) {
          return state.apiToken && state.user
        },
        loggedUser (state) {
          return state.user
        },
        apiToken (state) {
          return state.apiToken
        },
        checkCompanyPackageId (state) {
          let packageId = null
          if (state.user.companies) {
            const response = state.user.companies[0].package_id
            if (response) {
              packageId = response
            }
          } else {
            packageId = 0
          }
          return packageId
        },
        companyLocation (state) {
          let location = {
            lat: 45.6464113,
            lng: 25.6270105
          }
          if (state.user.companies[0].lat) {
            location = {
              lat: parseFloat(state.user.companies[0].lat),
              lng: parseFloat(state.user.companies[0].lng)
            }
          }
          return location
        },
        printCompanyPackageName (state) {
          let roleName = ''
          const packageId = 0
          if (state.user.companies) {
            const packageId = state.user.companies[0].package_id
            if (packageId === 1) {
              roleName = 'TAXI'
            }
            if (packageId === 2) {
              roleName = 'Delivery'
            }
            if (packageId === 3) {
              roleName = 'FOOD'
            }
          } else {
            if (packageId === 0) {
              roleName = 'MEDIA'
            }
          }
          return roleName
        }
      },
      mutations: {
        setUserData (state, {
          user,
          token,
          meta
        }) {
          state.user = user
          state.apiToken = token
          localStorage.setItem('loggedUser', JSON.stringify(user))
          localStorage.setItem('apiToken', token)

          const metaItems = {}
          meta.forEach((item) => {
            metaItems[item.key] = item.value
          })
          state.meta = metaItems
          localStorage.setItem('meta', JSON.stringify(metaItems))
        },
        logout (state) {
          state.user = null
          state.apiToken = null
          state.meta = null
          localStorage.removeItem('loggedUser')
          localStorage.removeItem('apiToken')
          localStorage.removeItem('meta')
        }
      }
    },
    dispatcherDashboard: {
      namespaced: true,
      state: {
        ordersList: [],
        orderInView: null,
        services: [],
        orderInPreview: null,
        newOrdersList: [],
        quickEditOrderId: null,
        selectOpen: false
      },
      getters: {
        getOrders (state) {
          return state.ordersList
        },
        getOrderInView (state) {
          return state.orderInView
        },
        getServices (state) {
          return state.services
        },
        orderInQuickEdit (state) {
          return state.newOrdersList.find((order) => {
            return order.id === state.quickEditOrderId
          })
        }
      },
      mutations: {
        setOrders (state, payload) {
          state.ordersList = payload.orders
        },
        setNewOrders (state, payload) {
          state.newOrdersList = []
          window.rowKey = 0
          state.newOrdersList = payload.orders
        },
        removeOrder (state, payload) {
          const index = state.ordersList.findIndex((order) => order.id === payload.orderId)
          if (typeof payload.predicate === 'function') {
            if (payload.predicate(state.ordersList[index]) === false) {
              return
            }
          }
          state.ordersList.splice(index, 1)
        },
        setOrderInView (state, payload) {
          state.orderInView = payload.order
        },
        setServices (state, payload) {
          state.services = payload.services
        },
        addNewPlaceToOrder (state) {
          state.orderInView.places.push((() => {
            return {
              name: '',
              place_id: null,
              long: null,
              lat: null,
              scara: '',
              bloc: '',
              reper: ''
            }
          })())
        },
        markOrderAsHold (state, payload) {
          const order = state.ordersList.find((order) => {
            return order.id === payload.orderId
          })
          if (order) {
            order.call.state = 'on_hold'
          }
        },
        removePlaceFromOrder (state, payload) {
          state.orderInView.places.splice(payload.index, 1)
        },
        createNewOrder (state) {
          state.orderInView = (() => {
            return {
              departure_time: null,
              service_id: 1,
              phone: '',
              name: null,
              client_name: null,
              about_client: '',
              places: [
                {
                  name: '',
                  long: null,
                  lat: null,
                  place_id: null,
                  comment: '',
                  scara: '',
                  bloc: '',
                  reper: ''
                }
              ],
              options: [],
              state: 'new',
              dispatcher_id: null,
              custom_driver_ids: [],
              payment_type: 'cash',
              client: {
                protocol_parent_user: null,
                about: ''
              },
              search_company_ids: this.getters['auth/loggedUser'].companies.map(company => company.id)
            }
          })()
        },
        setHoldCallForOrder (state, payload) {
          const order = state.ordersList.find((order) => {
            return order.id === payload.orderId
          })

          if (order) {
            order.call.state = 'on_hold'
          }
          if (state.orderInView && state.orderInView.id === payload.orderId && state.orderInView.call) {
            state.orderInView.call.state = 'on_hold'
          }
        },
        setUnHoldCallForOrder (state, payload) {
          const order = state.ordersList.find((order) => {
            return order.id === payload.orderId
          })

          if (order) {
            order.call.state = 'started'
          }
          if (state.orderInView && state.orderInView.id === payload.orderId && state.orderInView.call) {
            state.orderInView.call.state = 'started'
          }
        },
        setCallEndedForOrder (state, payload) {
          const order = state.ordersList.find((order) => {
            return order.id === payload.orderId
          })

          if (order) {
            order.call.state = 'ended'
          }
          if (state.orderInView && state.orderInView.id === payload.orderId && state.orderInView.call) {
            state.orderInView.call.state = 'ended'
          }
        },
        refreshOrder (state, payload) {
          let index = null
          for (var i in state.ordersList) {
            if (state.ordersList[i].id === payload.order.id) {
              index = i
              break
            }
          }
          if (index) {
            state.ordersList.splice(index, 1, payload.order)
          }
        }
      },
      actions: {
        getOrders (context, payload) {
          let url
          switch (payload.type) {
            case 'inCall':
              url = config.baseApiUrl + '/api/dispatcher-orders/in-call'
              break
            case 'live':
              url = config.baseApiUrl + '/api/dispatcher-orders/live'
              break
            case 'finished':
              url = config.baseApiUrl + '/api/dispatcher-orders/finished'
              break
            case 'canceled':
              url = config.baseApiUrl + '/api/dispatcher-orders/canceled'
              break
          }
          this._vm.$axios.get(url)
            .then(response => {
              context.commit('setOrders', {
                orders: response.data
              })
            })
        },
        getNewOrders (context, payload) {
          let url
          switch (payload.type) {
            case 'inCall':
              url = config.baseApiUrl + '/api/dispatcher-orders/in-call'
              break
            case 'live':
              url = config.baseApiUrl + '/api/dispatcher-orders/live'
              break
            case 'finished':
              url = config.baseApiUrl + '/api/dispatcher-orders/finished'
              break
            case 'canceled':
              url = config.baseApiUrl + '/api/dispatcher-orders/canceled'
              break
          }
          this._vm.$axios.get(url)
            .then(response => {
              context.commit('setNewOrders', {
                orders: response.data
              })
            })
        },
        viewOrder (context, payload) {
          this._vm.$axios.get(config.baseApiUrl + '/api/orders/' + payload.orderId)
            .then(response => {
              context.commit('setOrderInView', {
                order: response.data
              })
            })
        },
        getServices (context) {
          this._vm.$axios.get(config.baseApiUrl + '/api/services')
            .then(response => {
              context.commit('setServices', {
                services: response.data.data
              })
            })
        },
        createOrder (context) {
          console.log(context.state.orderInView)
          return this._vm.$axios.post(config.baseApiUrl + '/api/orders/', context.state.orderInView)
        },
        updateOrder (context) {
          console.log(context.state.orderInView)
          return this._vm.$axios.patch(config.baseApiUrl + '/api/orders/' + context.state.orderInView.id, context.state.orderInView)
        },
        acceptCall (context, payload) {
          return this._vm.$axios.post(config.baseApiUrl + '/api/calls/accept', {
            phone_number: payload.phoneNumber,
            sip_caller_id: payload.sipCallerId
          })
        },
        updateClientName (context, payload) {
          return this._vm.$axios.patch(config.baseApiUrl + '/api/orders/' + context.state.orderInView.id + '/update-name', {
            name: payload.name,
            about: payload.about
          })
        },
        updateOrderPlace (context, payload) {
          return this._vm.$axios.patch(config.baseApiUrl + '/api/orders/' + payload.orderPlace.order_id + '/places/' + payload.orderPlace.id, payload.orderPlace)
            .then(() => {
              context.state.orderInView.places[0].scara = payload.orderPlace.scara ?? null
              context.state.orderInView.places[0].bloc = payload.orderPlace.bloc ?? null
              context.state.orderInView.places[0].reper = payload.orderPlace.reper ?? null
            })
        }
      }
    },
    sip: {
      namespaced: true,
      state: {
        isSipConnected: false,
        calls: {},
        list: []
      },
      getters: {
        phoneList (state) {
          return state.list.map(i => i.phone)
        },
        pendingCalls (state, getters) {
          const pendingCalls = {}
          for (const phone in state.calls) {
            if (state.calls[phone].callSession.isInProgress() && !getters.phoneList.includes(phone)) {
              pendingCalls[phone] = state.calls[phone]
            }
          }
          return pendingCalls
        },
        activeCall (state) {
          for (const phone in state.calls) {
            if (state.calls[phone].callSession._is_confirmed && !state.calls[phone].callSession._localHold) {
              return state.calls[phone]
            }
          }
          return null
        },
        activeCallPhone (state) {
          for (const phone in state.calls) {
            if (state.calls[phone].callSession._is_confirmed && !state.calls[phone].callSession._localHold) {
              return phone
            }
          }
          return null
        }
      },
      mutations: {
        setList (state, payload) {
          state.list = payload
        },
        addCall (state, payload) {
          Vue.set(state.calls, payload.phoneNumber.toLowerCase(), {
            phoneNumber: payload.phoneNumber,
            callSession: payload.callSession,
            orderId: payload.orderId
          })
        },
        removeCall (state, payload) {
          Vue.delete(state.calls, payload.phoneNumber)
        }
      },
      actions: {
        getList (context) {
          return this._vm.$axios.get(config.baseApiUrl + '/api/blocked-phone')
            .then((response) => {
              context.commit('setList', response.data)
              return response
            })
        },
        callEnded (context, payload) {
          return this._vm.$axios.patch(config.baseApiUrl + '/api/calls/cancel', {
            phone_number: payload.phoneNumber,
            reason_type: payload.reasonType,
            reason_text: payload.reasonText
          })
        }
      }
    },
    userAddresses: {
      namespaced: true,
      state: {
        addresses: []
      },
      actions: {
        getUserAddresses (context, payload) {
          return this._vm.$axios.get(config.userAddressesUrl + '/addresses/' + payload.phone)
            .then((response) => {
              context.commit('setAddresses', {
                addresses: response.data
              })
              return response
            })
        }
      },
      mutations: {
        setAddresses (state, payload) {
          state.addresses = payload.addresses
        }
      }
    },
    messages: {
      namespaced: true,
      state: {
        isOpen: false,
        unreadCount: 0,
        conversations: [],
        activeConversationIndex: 0,
        messages: [],
        messageToSend: '',
        selectedUser: null
      },
      getters: {
        getConversations (state) {
          return state.conversations
        },
        getMessages (state) {
          return state.messages
        },
        getOpenedConversation (state) {
          if (typeof state.conversations[state.activeConversationIndex - 1] !== 'undefined') {
            return state.conversations[state.activeConversationIndex - 1]
          }
          return null
        }
      },
      mutations: {
        setConversations (state, payload) {
          state.conversations = payload.conversations
        },
        setMessages (state, payload) {
          state.messages = payload.messages
        },
        unshiftMessages (state, payload) {
          state.messages.unshift(...payload.messages.reverse())
        },
        pushMessage (state, payload) {
          state.messages.push(payload.message)
          this._vm.$socket.send(JSON.stringify({
            event: 'messageRead',
            payload: {
              message: payload.message
            }
          }))
        },
        addNewConversation (state, payload) {
          state.messages = []
          for (const index in state.conversations) {
            if (state.conversations[index].interlocutor_id === payload.conversation.interlocutor_id) {
              state.activeConversationIndex = parseInt(index) + 1
              return
            }
          }
          state.conversations.unshift(payload.conversation)
          state.activeConversationIndex = 1
        },
        incrementUnreadCount (state) {
          state.unreadCount++
        },
        decrementUnreadCount (state) {
          state.unreadCount--
        }
      },
      actions: {
        fetchConversations (context) {
          return this._vm.$axios.get(config.baseApiUrl + '/api/messages/self-interlocutors')
            .then((response) => {
              context.commit('setConversations', {
                conversations: response.data
              })
            })
        },
        fetchMessages (context, payload) {
          return this._vm.$axios.get(config.baseApiUrl + '/api/messages/self', {
            params: {
              page: payload.page,
              type: payload.type,
              receiver_id: payload.interlocutor_id
            }
          })
        },
        sendMessage (context, payload) {
          return this._vm.$axios.post(config.baseApiUrl + '/api/messages/to-user', payload)
        },
        messageReceived (context, payload) {
          if (context.getters.getOpenedConversation === null || context.getters.getOpenedConversation.interlocutor_id !== payload.message.sender_id) {
            context.commit('incrementUnreadCount')
          }
        },
        openConversation (context, payload) {
          if (context.state.messages.activeConversationIndex - 1 !== payload.index) {
            while (payload.conversation.unread_count > 0) {
              payload.conversation.unread_count--
              context.commit('decrementUnreadCount')
            }
            payload.conversation.has_unread = false
            context.commit('setMessages', {
              messages: []
            })
          }
        }
      }
    },
    discounts: {
      namespaced: true,
      state: {
        list: null,
        inEdit: null
      },
      getters: {
        getById (state) {
          return (id) => {
            return state.list.find((item) => {
              return item.id === id
            })
          }
        }
      },
      mutations: {
        setDiscounts (state, payload) {
          state.list = payload
        }
      },
      actions: {
        fetchDiscounts (context) {
          return this._vm.$axios.get(config.baseApiUrl + '/api/discounts')
            .then((response) => {
              context.commit('setDiscounts', response.data)
            })
        }
      }
    }
  }
})
