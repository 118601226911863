<template>
  <div>
    <button v-shortkey.once="['+']" @shortkey="addNewOrder" class="d-none"></button>
    <button v-shortkey.once="['-']" @shortkey="closeOrderForm" class="d-none"></button>
    <button v-if="!orderInQuickEdit" v-shortkey.once="['space']" @shortkey="quickAcceptCall" class="d-none"></button>
    <div class="split-orders">
      <router-view></router-view>
    </div>
    <div class="split-map">
      <DriversMap  />
    </div>
    <div v-if="orderInQuickEdit" class="fixed-order-form">
      <AddOrderForm :order="orderInQuickEdit" @place-order="_placeOrder"></AddOrderForm>
    </div>
  </div>
</template>

<script>
import DriversMap from '@/components/dashboard/Operator/DriversMap'
import jsSip from '@/services/jsSip'
import ordersService from '@/services/orders'
import AddOrderForm from '@/components/dashboard/AddOrderForm'

export default {
  name: 'OperatorDashboardV2',
  components: { AddOrderForm, DriversMap },
  data () {
    return {
      isProtocol: false,
      newOrder: null
    }
  },
  computed: {
    pendingCalls () {
      return this.$store.getters['sip/pendingCalls']
    },
    pendingCallsCount () {
      return Object.keys(this.pendingCalls).length
    },
    freeLinesCount () {
      return this.pendingCallsCount >= 15 ? 0 : (15 - this.pendingCallsCount)
    },
    orderInQuickEdit () {
      return this.newOrder ? this.newOrder : this.$store.getters['dispatcherDashboard/orderInQuickEdit']
    }
  },
  mounted () {
    this.$eventBus.addEventListener('quickOrderPlaced', () => {
      this.newOrder = null
    }, 'quickOrderPlaced')
  },
  beforeDestroy () {
    this.$eventBus.removeEventListener('quickOrderPlaced', 'quickOrderPlaced')
  },
  methods: {
    closeOrderForm () {
      this.newOrder = null
      this.$store.state.dispatcherDashboard.quickEditOrderId = null
    },
    acceptCall (payload) {
      console.log('HERE', payload)
      this.$store.dispatch('dispatcherDashboard/acceptCall', payload)
        .then((response) => {
          this.$store.state.dispatcherDashboard.quickEditOrderId = response.data.id
          for (const phone in this.$store.state.sip.calls) {
            if (this.$store.state.sip.calls[phone].callSession._is_confirmed === true) {
              if (payload.phoneNumber !== phone && this.$store.state.sip.calls[phone].callSession.isOnHold().local === false) {
                this.$store.commit('dispatcherDashboard/markOrderAsHold', {
                  orderId: this.$store.state.sip.calls[phone].orderId
                })
                this.$store.state.sip.calls[phone].callSession.hold(jsSip.holdUnholdOptions)
              }
            }
          }
          this.$store.state.sip.calls[payload.phoneNumber].orderId = response.data.id
          this.$store.state.sip.calls[payload.phoneNumber].callSession.answer(jsSip.answerOptions)
          this.$bvToast.hide('new-call-toast-' + payload.phoneNumber)
          if (this.$route.name === 'OrdersList') {
            this.$store.state.dispatcherDashboard.newOrdersList.unshift(response.data)
          } else {
            this.$router.push({ name: 'OperatorOrderView', params: { id: response.data.id } })
          }
        })
    },
    _placeOrder (order) {
      ordersService.placeOrder(order, this)
    },
    quickAcceptCall () {
      if (this.$store.state.dispatcherDashboard.quickEditOrderId === null) {
        const phones = Object.keys(this.pendingCalls)
        if (typeof this.pendingCalls[phones[0]] !== 'undefined') {
          console.log(this.pendingCalls[phones[0]])
          // this.acceptCall({ phoneNumber: this.pendingCalls[phones[0]].phoneNumber })
        }
      }
    },
    addNewOrder () {
      this.newOrder = {
        id: null,
        departure_time: this.$day().format('YYYY-MM-DD HH:mm:ss'),
        service_id: 1,
        phone: '',
        name: null,
        places: [{ name: '', long: null, lat: null, place_id: null, comment: null }],
        options: [],
        state: 'new',
        dispatcher_id: this.$store.state.auth.user.id,
        custom_driver_ids: [],
        payment_type: 'cash',
        client: { protocol_parent_user: null, about: '' }
      }
    }
  }
}
</script>

<style scoped>
  .btn.btn-outline-dark {
    outline: none !important;
    box-shadow: none;
  }
  .btn.btn-outline-dark.router-link-exact-active {
    color: #ffffff;
    background-color: #343a40 !important;
  }
</style>
