<template>
  <div class="active-call bg-white" style="border: 1px solid #ccc; padding: 2px; border-radius: 4px;">
    <span>
      {{ activeCall.phoneNumber }}
    </span>
    <b-button @click="holdCall" :variant="activeCall.callSession.isOnHold().local ? 'success' : 'warning'" class="mr-2" style="padding: 3px 5px;">
      <b-icon-pause-circle v-if="!activeCall.callSession.isOnHold().local"></b-icon-pause-circle>
      <b-icon-play-circle v-if="activeCall.callSession.isOnHold().local"></b-icon-play-circle>
    </b-button>
    <b-button @click="endCall" variant="danger" style="padding: 3px 5px;">
      <b-icon-stop-circle></b-icon-stop-circle>
    </b-button>
  </div>
</template>

<script>
import jsSip from '@/services/jsSip'

export default {
  name: 'ActiveCall',
  props: {
    activeCall: {
      type: Object,
      required: true
    },
    phone: {
      type: String,
      required: true
    }
  },
  methods: {
    holdCall () {
      if (this.activeCall.callSession._localHold) {
        for (const phone in this.$store.state.sip.calls) {
          if (phone !== this.clientPhone && this.$store.state.sip.calls[phone].callSession._is_confirmed && this.$store.state.sip.calls[phone].callSession.isOnHold().local === false) {
            this.$store.state.sip.calls[phone].callSession.hold(jsSip.holdUnholdOptions)
          }
        }
        this.$store.state.sip.calls[this.phone].callSession.unhold(jsSip.holdUnholdOptions)
      } else {
        this.activeCall.callSession.hold(jsSip.holdUnholdOptions)
      }
    },
    endCall () {
      this.activeCall.callSession.terminate()
      this.$store.commit('sip/removeCall', {
        phoneNumber: this.phone
      })
    }
  }
}
</script>

<style scoped>
  .active-call {
    margin-right: 10px;
  }
  .active-call:last-of-type {
    margin-right: 0;
  }
</style>
