<template>
  <div class="container-fluid">
    <b-row>
      <b-col md="2">
        <UserSelect @input="addSimulator" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <b-row>
          <b-col md="3" class="my-3" v-for="driver in drivers" :key="driver.id">
            <SimulatorItem :driver="driver" />
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mt-3">
        <DriversMap class="card" :drivers="drivers" @position_changed="updateLoc" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SimulatorItem from './SimulatorItem'
import UserSelect from '@/components/common/UserSelect'
import config from '@/config'
import axios from 'axios'
import DriversMap from './DriversMap.vue'

export default {
  name: 'Simulator',
  components: { SimulatorItem, UserSelect, DriversMap },
  data () {
    return {
      index: null,
      drivers: []
    }
  },
  methods: {
    updateLoc (data) {
      const driver = this.drivers.find((driver) => {
        return driver.driver.id === data.driver_id
      })
      if (driver) {
        driver.positions.lat = data.event.latLng.lat()
        driver.positions.lng = data.event.latLng.lng()
      }
    },
    addSimulator (driver) {
      axios.post(config.baseApiUrl + '/api/access-tokens', {
        email: driver.email,
        password: '123456'
      }).then((response) => {
        const apiToken = response.data.token
        axios.patch(config.baseApiUrl + '/api/user-activity', {
          isOnline: true
        }, {
          headers: {
            Authorization: 'Bearer ' + apiToken
          }
        })
        const wsConnection = new WebSocket('wss://02.services.ogo.taxi?token=' + apiToken)
        wsConnection.onopen = () => {
          wsConnection.addEventListener('message', (event) => {
            // console.log(event)
          })
          this.drivers.push({
            driver,
            apiToken,
            wsConnection,
            positions: {
              lat: this.$store.getters['auth/companyLocation'].lat,
              lng: this.$store.getters['auth/companyLocation'].lng
            },
            onSearchNow: false
          })
        }
      })
      // console.log(driver)
    }
  }
}
</script>
