<template>
  <b-list-group-item   class="p-0 order-item" :class="{'order-pending': order.in_pending_queue === true}" @click.right.prevent="rightClick(order.id)">
    <div class="order-menu" v-if="showOrderMenu" @focusout="closeOrderMenu">
      <b-button-group vertical>
        <b-button @click="removeOrder(idx)">Șterge comanda</b-button>
        <b-button variant="danger" @click="closeOrderMenu">Închide</b-button>
      </b-button-group>
    </div>
    <div class="details align-items-center" :class="this.order.address ? '' : 'not-have-addr'" >
      <div class="sipControl text-center" v-if="getShortkeysType() === 'lines'">
          <b-button  size="sm" style="width: 34px;" :variant="order.__call_index !== null ? 'dark' : 'light'">
            <template v-if="order.__call_index">F{{ order.__call_index }}</template>
            <template v-else> --</template>
          </b-button>
          <!-- {{ getRowLabel(order) }} -->
      </div>
      <div class="col-12 mr-auto ">
        <router-link :to="{ name: 'OperatorOrderView', params: { id: order.id } }" class="py-0">
          <span class="mr-3">#{{ order.id }}</span>
          <DateTime :date-time="order.created_at" format="HH:mm:ss"></DateTime>
          <strong class="mx-3">{{ order.client_phone }}</strong>

          <b-badge v-if="(order.state !== 'pending' || order.state !== 'created') && order.in_pending_queue === true" variant="info">Alertă!</b-badge>
          <div class="address">
            <div v-if="order.address">
              <span v-if="order.address.length >= 70">
                {{ order.address.substring(0,55)+".." }}
              </span>
              <span v-else>{{ order.address }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="order-sip">

        <div class="d-flex justify-content-end align-items-center text-right">
          <b-icon-bullseye class="pull-right mr-2" :class="orderItemClass"></b-icon-bullseye>
          <b-icon-bullseye v-if="(order.state !== 'pending' || order.state !== 'created') && order.in_pending_queue === true" class="pull-right mx-1" animation="throb" variant="info"></b-icon-bullseye>
          <b-button
            v-if="order.driver_id"
            size="sm" variant="success" class="orderDriverInd pull-right mr-1"
          >
            {{ order.car_indicativ }}
            <template v-if="order.waiting_driver_time"> - {{ order.waiting_driver_time }}m</template>
          </b-button>
          <b-button
            v-else-if="order.cancelled_by_identifier"
            size="sm" variant="danger" class="orderDriverInd pull-right mr-1"
          >
            {{ order.cancelled_by_identifier }}
          </b-button>
          <b-button v-if="!order.dispatcher_id" class="mr-1" size="sm" variant="dark"><b-icon icon="globe" /></b-button>
          <template v-if="orderCallSession">
            <b-button-group class="mx-1">
            <b-button @click="holdCall" v-if="!orderCallSession.isOnHold().local" size="sm" variant="warning" class="sip-hold-unhold-control px-3">
              <b-icon icon="pause-circle"></b-icon>
            </b-button>
            <b-button @click="unholdCall" v-if="orderCallSession.isOnHold().local" size="sm" variant="success" class="sip-hold-unhold-control px-3">
              <b-icon icon="play-circle"></b-icon>
            </b-button>
            <b-button @click="endCall" id="cancel-call"  variant="danger" size="sm" class="sip-end-control" :class="{'active-call': !orderCallSession.isOnHold().local}">
              <b-icon icon="stop-circle"></b-icon>
            </b-button>
          </b-button-group>
          </template>
          <template v-if="isMyOrder">
            <b-button v-if="orderCallSession && order.driver_id !== null" size="sm" variant="light" class="ml-2 d-none">
              <b-icon-music-note-beamed v-if="order.call_playback_state === 'started'" animation="throb" variant="success"></b-icon-music-note-beamed>
              <b-icon-music-note-beamed v-if="order.call_playback_state === 'ended'" variant="warning"></b-icon-music-note-beamed>
            </b-button>
            <b-button v-if="orderCallSession && order.call_need_interaction" size="sm" variant="light" class="ml-2">
              <b-icon-exclamation-triangle animation="throb" variant="danger"></b-icon-exclamation-triangle>
            </b-button>
            <b-button
              v-if="order.state === 'canceled' || order.state === 'pending'"
              @click="retryOrder"
              style="background: #563d7c; border-color: #563d7c;" size="sm" variant="info" class="ml-2"
            >
              <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
            </b-button>
          </template>
          <b-button
            v-if="!orderCallSession && order.always_in_call && order.call_state === 'ended' || !order.call_state"
            @click="removeAlwaysInCall(idx)"
            size="sm" :variant="order.state === 'canceled' || order.state === 'pending' ? 'outline-danger' : 'outline-info'" class="ml-2"
            :title="order.state === 'canceled' || order.state === 'pending' ? 'In anulate' : 'Confirmata'"
          >
            <b-icon-arrow-right-circle></b-icon-arrow-right-circle>
          </b-button>
          <b-button v-else size="sm" variant="secondary" disabled class="ml-2">
            <b-icon-arrow-right-circle></b-icon-arrow-right-circle>
          </b-button>
        </div>
        <div v-if="order.call_state" class="pt-0 text-right" style="min-height: 15px;">
          <span v-if="order.call_state !== 'ended'">
            {{ activeCallDuration }}
          </span>
          <span v-else>
            {{ endedCallDuration }}
          </span>
        </div>
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
import DateTime from '@/components/common/DateTime'
import config from '@/config'
import jsSip from '@/services/jsSip'
import axios from 'axios'
import circularJSON from 'circular-json'
export default {
  name: 'OrderListItem',
  components: { DateTime },
  props: {
    order: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      localStorage: {
        lineAnswer: null
      },
      callInUpdate: false,
      potentialDriver: null,
      showOrderMenu: false
    }
  },
  computed: {
    orderItemClass () {
      if (this.order.state === 'created') {
        return 'text-warning'
      }
      if (this.order.state === 'assigned') {
        return 'text-success'
      }
      if (this.order.state === 'canceled') {
        return 'text-danger'
      }
      return ''
    },
    clientPhone () {
      return this.order.client_phone?.toLowerCase()
    },
    activeCallDuration () {
      return this.$day.duration(
        this.$store.state.now.diff(
          this.$day.utc(this.order.call_started_at).local()
        )
      ).format('mm:ss')
    },
    endedCallDuration () {
      return this.$day.duration(
        this.$day.utc(this.order.call_ended_at).diff(
          this.$day.utc(this.order.call_started_at)
        )
      ).format('mm:ss')
    },
    isMyOrder () {
      return this.order.dispatcher_id === this.$store.getters['auth/loggedUser'].id
    },
    orderCallSession () {
      if (typeof this.$store.state.sip.calls[this.clientPhone] !== 'undefined') {
        return this.$store.state.sip.calls[this.clientPhone].callSession
      }
      return null
    }
  },
  mounted () {
    this.getShortkeysType()
    window.rowKeyArr = {}

    this.$eventBus.addEventListener('ws_order_sent_to_driver', (data) => {
      if (data.order.id === this.order.id) {
        this.potentialDriver = data.driver
      }
    }, 'listItem' + this.order.id)
  },
  beforeDestroy () {
    this.$eventBus.removeEventListener('ws_order_sent_to_driver', 'listItem' + this.order.id)
  },
  methods: {
    removeOrder (idx) {
      if (confirm('Sigur vrei să ștergi comanda?')) {
        this.removeAlwaysInCall(idx)
      }
    },
    rightClick () {
      this.showOrderMenu = true
    },
    closeOrderMenu () {
      this.showOrderMenu = false
    },
    getShortkeysType () {
      if (localStorage.lineAnswer) {
        return localStorage.lineAnswer
      } else {
        return 'simple'
      }
    },
    getRowLabel (order) {
      if (typeof this.$store.state.sip.calls[order.client_phone] !== 'undefined') {
        window.rowKeyArr[order.client_phone] = true
        return 'F' + Object.keys(window.rowKeyArr).length
      }
      return '- -'
    },
    holdCall () {
      this.callInUpdate = true
      this.$store.state.sip.calls[this.clientPhone].callSession.hold(jsSip.holdUnholdOptions)
      this.callInUpdate = false
    },
    unholdCall () {
      this.callInUpdate = true
      for (const phone in this.$store.state.sip.calls) {
        if (phone !== this.clientPhone && this.$store.state.sip.calls[phone].callSession._is_confirmed && this.$store.state.sip.calls[phone].callSession.isOnHold().local === false) {
          this.$store.state.sip.calls[phone].callSession.hold(jsSip.holdUnholdOptions)
        }
      }
      this.$store.state.sip.calls[this.clientPhone].callSession.unhold(jsSip.holdUnholdOptions)
      this.callInUpdate = false

      this.$axios.patch(config.baseApiUrl + '/api/calls/unhold/' + this.order.id)
    },
    endCall () {
      this.callInUpdate = true
      const session = this.$store.state.sip.calls[this.clientPhone].callSession
      session.terminate()
      this.$store.commit('sip/removeCall', {
        phoneNumber: this.clientPhone
      })
      this.$axios.patch(config.baseApiUrl + '/api/calls/cancel/', {
        phone_number: this.clientPhone
      })
        .catch((error) => {
          try {
            axios.post('https://hooks.slack.com/services/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', {
              text: circularJSON.stringify(error)
            }, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
          } catch (e) {

          }
        })
        .then(() => {
          this.callInUpdate = false
          this.$store.commit('dispatcherDashboard/removeOrder', {
            orderId: this.order.id
          })
        })
    },
    retryOrder () {
      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/retry')
        .then(() => {
          this.order.in_pending_queue = false
          this.order.state = 'created'
        })
    },
    removeAlwaysInCall () {
      this.$emit('remove-from', this.order.id)
      this.$axios.delete(config.baseApiUrl + '/api/orders/' + this.order.id + '/always-in-call')
        .then(() => {
          this.order.always_in_call = false
        })
    },
    moveToPendingQueue () {
      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/move-to-pending')
        .then(() => {
          this.order.in_pending_queue = true
        })
    }
  },
  watch: {
    'order.id': function (value, oldValue) {
      this.potentialDriver = null
      this.$eventBus.removeEventListener('ws_order_sent_to_driver', 'listItem' + oldValue)
      this.$eventBus.addEventListener('ws_order_sent_to_driver', (data) => {
        if (data.order.id === value) {
          this.potentialDriver = data.driver
        }
      }, 'listItem' + value)
    }
  }
}
</script>

<style scoped>
.order-menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}
</style>
