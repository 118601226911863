<template>
  <div>
    <b-table hover :fields="fields" :items="orders"  :tbody-tr-class="rowClass" :busy="isBusy" borderless
      :per-page="perPage"
      :current-page="currentPage">
      <template #cell(places)="data">
        {{ data.value[0].name }}
      </template>
      <template #cell(driver.identifier)="data">
        <b-badge variant="success" v-if="data.value">
          {{ data.value }}
        </b-badge>
        <span v-else>-</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Se incarcă ...</strong>
        </div>
      </template>

    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      class="mt-3"
    ></b-pagination>
  </div>
</template>

<script>
import config from '@/config'
import dayjs from 'dayjs'

export default {
  name: 'ClientHistory',
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  computed: {
    rows () {
      return this.orders.length
    }
  },
  data () {
    return {
      perPage: 15,
      currentPage: 1,
      isBusy: true,
      fields: [
        { key: 'id', label: '#' },
        { key: 'places', label: 'Adresa' },
        {
          key: 'created_at',
          label: 'Data',
          formatter: (value) => {
            return dayjs(value + 'Z').local().format('DD.MM.YYYY HH:mm')
          }
        },
        { key: 'driver.identifier', label: 'Sofer' },
        { key: 'state', label: 'Status' }
      ],
      orders: []
    }
  },
  mounted () {
    this.getOrders()
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.state === 'finished') return 'table-success'
    },
    getOrders () {
      this.$axios.get(config.baseApiUrl + '/api/orders?client_id=' + this.clientId + '&per_page=30').then((response) => {
        this.orders = response.data.data
        this.isBusy = false
      })
    }
  }
}
</script>
