<template>
  <div class="mt-2">
    <div class="d-flex orders-navigation">
      <button @click="ordersView = 'inCall'" class="btn btn-sm" :class="{'btn-primary': ordersView === 'inCall', 'btn-outline-info': ordersView !== 'inCall'}">
        <span class="d-inline d-md-none">L</span>&nbsp;
        <span class="d-none d-md-inline">Live</span>&nbsp;
        <span class="badge badge-light">--</span>
      </button>
      <button @click="ordersView = 'live'" class="btn btn-sm ml-2" :class="{'btn-primary': ordersView === 'live', 'btn-outline-primary': ordersView !== 'live'}">
        <span class="d-inline d-md-none">C</span>&nbsp;
        <span class="d-none d-md-inline">Confirmate</span>&nbsp;
        <span class="badge badge-light">{{ ordersStatistics.liveOrdersCount }}</span>
      </button>
      <button @click="ordersView = 'finished'" class="btn btn-sm ml-2" :class="{'btn-primary': ordersView === 'finished', 'btn-outline-success': ordersView !== 'finished'}">
        F<span class="d-none d-md-inline">inal</span>&nbsp;
        <span class="badge badge-light">{{ ordersStatistics.finishedOrdersCount }}</span>
      </button>
      <button @click="ordersView = 'canceled'" class="btn btn-sm ml-2" :class="{'btn-primary': ordersView === 'canceled', 'btn-outline-danger': ordersView !== 'canceled'}">
        A<span class="d-none d-md-inline">nulat</span>&nbsp;
        <span class="badge badge-light">{{ ordersStatistics.canceledOrdersCount }}</span>
      </button>
    </div>
    <div class="SideBar">
      <InCallOrders v-if="ordersView === 'inCall'"></InCallOrders>
      <LiveOrders v-else-if="ordersView === 'live'"></LiveOrders>
      <FinishedOrders v-else-if="ordersView === 'finished'"></FinishedOrders>
      <CanceledOrders v-else-if="ordersView === 'canceled'"></CanceledOrders>
    </div>
  </div>
</template>

<script>

import InCallOrders from '@/components/dashboard/Operator/Orders/InCallOrders'
import LiveOrders from '@/components/dashboard/Operator/Orders/LiveOrders'
import FinishedOrders from '@/components/dashboard/Operator/Orders/FinishedOrders'
import CanceledOrders from '@/components/dashboard/Operator/Orders/CanceledOrders'
import config from '@/config'

export default {
  name: 'SideBar',
  components: {
    InCallOrders,
    LiveOrders,
    FinishedOrders,
    CanceledOrders
  },
  data () {
    return {
      ordersView: 'inCall',
      ordersStatistics: {
        inCallOrdersCount: 0,
        liveOrdersCount: 0,
        finishedOrdersCount: 0,
        canceledOrdersCount: 0
      },
      updateInterval: null
    }
  },
  mounted () {
    this.getOrdersStatistics()
    this.updateInterval = setInterval(() => {
      this.getOrdersStatistics()
    }, 15000)
  },
  beforeDestroy () {
    clearInterval(this.updateInterval)
  },
  methods: {
    getOrdersStatistics () {
      this.$axios.get(config.baseApiUrl + '/api/dispatcher-orders/statistics')
        .then((response) => {
          this.ordersStatistics = response.data
        })
    }
  }
}
</script>

<style scoped>
.orders-navigation {
  padding: 0 10px;
}
</style>
